import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { Broadcast } from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
} from '#mrktbox/clerk/hooks/useData';
import useCache from '#mrktbox/clerk/hooks/useDataCache';
import useNotificationsAPI from '#mrktbox/clerk/hooks/api/useNotificationsAPI';

export type BroadcastIndex = DataIndex<Broadcast>;

export type IconElement = React.ReactElement<{
  icon? : React.ReactElement;
  size? : string;
  colour? : string;
}>;
export type ActionElement = React.ReactElement<{
  label : string;
  size? : string;
  variant? : string;
  colour? : string;
}>;

const MAX_AGE = 1000 * 60 * 60; //ms
const TIMEOUT_DURATION = 3000; //ms

interface NotificationStatus {
  broadcasts : {
    [id : number] : { aknowledged : boolean };
  },
}

export interface Notification {
  message : string;
  key : string;
  count? : number;
  colour? : string;
  icon? : IconElement;
  actions? : ActionElement | ActionElement[];
}

export interface NotificationContextReturn {
  broadcasts : BroadcastIndex | null;
  broadcastsLoaded : boolean;
  loadBroadcasts : () => void;
  refreshBroadcasts : () => Promise<DataIndex<Broadcast> | null>;
  refreshBroadcast : (id : number) => Promise<Broadcast | null>;
  retrieveBroadcasts : () => Promise<DataIndex<Broadcast> | null>;
  retrieveBroadcast : (id : number) => Promise<Broadcast | null>;
  acknowledgeBroadcast : (broadcast : Broadcast) => void;
  isBroadcastAcknowledged : (broadcast : Broadcast) => boolean;
  nextBroadcast : Broadcast | null;
  isBroadcasting : boolean;
  open : boolean;
  close : () => void;
  closed : () => void;
  notification : Notification | null;
  createNotification : (notification : Notification) => void;
}

interface NotificationProviderProps {
  children : React.ReactNode;
}

const NotificationContext = createContext<NotificationContextReturn>({
  broadcasts : null,
  broadcastsLoaded : false,
  loadBroadcasts : () => {},
  refreshBroadcasts : async () => null,
  refreshBroadcast : async () => null,
  retrieveBroadcasts : async () => null,
  retrieveBroadcast : async () => null,
  acknowledgeBroadcast : () => {},
  isBroadcastAcknowledged : () => false,
  nextBroadcast : null,
  isBroadcasting : false,
  open : false,
  close : () => {},
  closed : () => {},
  notification : null,
  createNotification : () => {},
});

export function NotificationProvider({ children } : NotificationProviderProps) {
  const {
    retrieveBroadcasts,
    retrieveBroadcast,
  } = useNotificationsAPI();

  const {
    data : broadcasts,
    dispatch : dispatchBroadcasts,
    lastUpdated,
  } = useData<Broadcast>({ storageKey : 'broadcasts' });

  let cachedStatus = { broadcasts : {} } as NotificationStatus;
  try {
    const status = localStorage.getItem('notificationStatus');
    if (status) cachedStatus = JSON.parse(status);
  } catch { }

  const [status, dispatchStatus] = useReducer((
    state : NotificationStatus,
    action : { type : string, data : any },
  ) => {
    switch (action.type) {
      case 'update':
        return {
          broadcasts : {
            ...state.broadcasts,
            ...action.data.broadcasts,
          },
        };
      default:
        return state;
    }
  }, cachedStatus);

  const [open, setOpen] = useState(false);
  const [queue, dispatchQueue] = useReducer((
    state : Notification[],
    action : { type : string, data? : Notification },
  ) => {
    switch (action.type) {
      case 'add':
        if (!action.data) return state;
        const last = state[state.length - 1] ?? null;
        if (last && last.key === action.data.key) {
          return [
            ...state.slice(0, state.length - 1),
            {
              ...last,
              count: (last.count ?? 1) + (action.data.count ?? 1),
            },
          ];
        }
        return [...state, action.data];
      case 'remove':
        return state.slice(1);
      default:
        return state;
    }
  }, []);
  const [
    timer,
    setTimer,
  ] = useState<ReturnType<typeof setTimeout> | null>();

  const notification = useMemo(() => queue[0] ?? null, [queue]);

  const broadcastsState = (lastUpdated !== undefined)
    && (new Date().getTime() - lastUpdated.getTime()) > MAX_AGE;

  const refreshBroadcasts = useCache({
    process : retrieveBroadcasts,
    dispatch : dispatchBroadcasts,
    refresh : true,
    isLoader : true,
  })
  const refreshBroadcast = useCache({
    process : retrieveBroadcast,
    dispatch : dispatchBroadcasts,
    isLoader : true,
    dropNull : true,
  })
  const getBroadcasts = useCache({
    process : retrieveBroadcasts,
    dispatch : dispatchBroadcasts,
    data : broadcasts,
    stale : broadcastsState,
    refresh : true,
    dropNull : true,
  })
  const getBroadcast = useCache({
    process : retrieveBroadcast,
    dispatch : dispatchBroadcasts,
    data : broadcasts,
    stale : broadcastsState,
    dropNull : true,
  })

  const { loaded : broadcastsLoaded, load : loadBroadcasts } = useLoad({
    data : broadcasts,
    loader : refreshBroadcasts,
  });

  const acknowledgeBroadcast = useCallback((broadcast : Broadcast) => {
    if (!broadcast.id) return;
    dispatchStatus({
      type : 'update',
      data : {
        broadcasts : {
          [broadcast.id] : { aknowledged : true },
        },
      },
    });
  }, [status, dispatchStatus]);

  const isBroadcastAcknowledged = useCallback((broadcast : Broadcast) => {
    if (!broadcast.id) return false;
    return status.broadcasts[broadcast.id]?.aknowledged ?? false;
  }, [status]);

  const resetTimer = useCallback(() => {
    if (timer) clearTimeout(timer);
    setTimer(setTimeout(() => { setOpen(false); }, TIMEOUT_DURATION));
  }, [ timer, setOpen, setTimer ]);

  const openNext = useCallback(() => {
    resetTimer();
    if (queue.length > 1) setOpen(true);
    dispatchQueue({ type : 'remove' });
  }, [
    queue,
    setOpen,
    resetTimer,
  ]);

  const close = useCallback(() => {
    if (timer) clearTimeout(timer);
    setTimer(null);
    setOpen(false);
  }, [ timer, setOpen, setTimer ]);

  const create = useCallback((newNotification : Notification) => {
    if (!open) {
      resetTimer();
      setOpen(true);
    }
    if (notification && notification.key === newNotification.key) resetTimer();
    dispatchQueue({ type : 'add', data : newNotification });
  }, [open, notification, resetTimer, setOpen]);

  const nextBroadcast = useMemo(
    () => (broadcasts
      ? Object.values(broadcasts).find((b) => (!!b
        && b.active
        && !isBroadcastAcknowledged(b)
      )) ?? null
      : null),
  [broadcasts, isBroadcastAcknowledged]);

  const isBroadcasting = useMemo(() => !!nextBroadcast, [nextBroadcast]);

  const context = useMemo(() => ({
    broadcasts,
    broadcastsLoaded,
    loadBroadcasts,
    refreshBroadcasts,
    refreshBroadcast,
    retrieveBroadcasts : getBroadcasts,
    retrieveBroadcast : getBroadcast,
    acknowledgeBroadcast,
    isBroadcastAcknowledged,
    nextBroadcast,
    isBroadcasting,
    open,
    close,
    closed : openNext,
    notification,
    createNotification : create,
  }), [
    broadcasts,
    broadcastsLoaded,
    loadBroadcasts,
    refreshBroadcasts,
    refreshBroadcast,
    getBroadcasts,
    getBroadcast,
    acknowledgeBroadcast,
    isBroadcastAcknowledged,
    nextBroadcast,
    isBroadcasting,
    open,
    close,
    openNext,
    notification,
    create,
  ]);

  useEffect(() => {
    try {
      localStorage.setItem('notificationStatus', JSON.stringify(status));
    } catch { }
  }, [status]);

  return (
    <NotificationContext.Provider value={context}>
      { children }
    </NotificationContext.Provider>
  );
}

export default NotificationContext;
