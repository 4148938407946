import { useCallback, useContext } from 'react';

import {
  Address,
  Assembly,
  Collection,
  Customer,
  Currency,
  Location,
  Product,
  ServiceChannel,
  TimeSlot,
  LineItem,
  Fulfilment,
  Selection,
  DraftCustomOrder,
} from '#mrktbox/clerk/types';

import OptionsContext from '#mrktbox/clerk/context/OptionsContext';

import useProducts from '#mrktbox/clerk/hooks/useProducts';
import useTags, { generateDefaultTag } from '#mrktbox/clerk/hooks/useTags';
import useOrders, { draftEmptyOrder } from '#mrktbox/clerk/hooks/useOrders';

import { multiplyCurrency } from '#mrktbox/clerk/utils';
import { listRecords } from '#mrktbox/clerk/utils/data';

export function generateDefaultAssembly() : Assembly {
  return {
    name : '',
    productIds : [],
    collectionIds : [],
    automatic : false,
    complimentary : false,
    cumulative : false,
  };
}

export function generateDefaultCollection() : Collection {
  return {
    ...generateDefaultTag(),
    min : 0,
    max : 0,
    starting : null,
    ending : null,
    tagIds : [],
    defaults : [],
  };
}

export function draftEmptyCustomOrder() : DraftCustomOrder {
  return {
    ...draftEmptyOrder(),
    selections : {},
  };
}

function useOptions() {
  const {
    products,
    loaded : productsLoaded,
    load : loadProducts,
  } = useProducts();
  const {
    tags,
    loaded : tagsLoaded,
    load : loadTags,
    addProductToTag,
    removeProductFromTag,
    getTagProducts,
  } = useTags();
  const {
    lineItems : allLineItems,
    loaded : ordersLoaded,
    load : loadOrders,
    findOrder,
    createDefaultOrder,
    generateDefaultFulfilment,
  } = useOrders();

  const contextHooks = useContext(OptionsContext);

  const customProducts = contextHooks.customProducts;
  const assembies = contextHooks.assemblies;
  const collections = contextHooks.collections;
  const selections = contextHooks.selections;
  const load = contextHooks.load;
  const createAssembly = contextHooks.createAssembly;
  const createCollection = contextHooks.createCollection;
  const refreshCollection = contextHooks.refreshCollection;
  const addAssemblyToProduct = contextHooks.addAssemblyToProduct;
  const addCollectionToAssembly = contextHooks.addCollectionToAssembly;
  const customiseOrder = contextHooks.customiseOrder;
  /** @deprecated */
  const bulkCreateSelections = contextHooks.bulkCreateSelections;
  /** @deprecated */
  const bulkUpdateSelections = contextHooks.bulkUpdateSelections;

  const createProductAssembly = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const newAssembly = await createAssembly(assembly);
    if (!newAssembly) return null;
    return addAssemblyToProduct(product, newAssembly);
  }, [createAssembly, addAssemblyToProduct]);

  const createAssemblyCollection = useCallback(async (
    assembly : Assembly,
    collection : Collection,
  ) => {
    const newCollection = await createCollection(collection);
    if (!newCollection) return null;
    return addCollectionToAssembly(assembly, newCollection);
  }, [createCollection, addCollectionToAssembly]);

  const addProductToCollection = useCallback(async (
    collection : Collection,
    product : Product,
  ) => {
    const newTag = await addProductToTag(collection, product);
    if (!newTag || !collection.id) return null;
    return refreshCollection(collection.id);
  }, [addProductToTag, refreshCollection]);

  /** @deprecated */
  const bulkUpdateCustomisedLineItems = useCallback(async (
    lineItems : LineItem[],
    itemSelections : Selection[],
  ) => {
    const removedSelections = listRecords(selections).filter(
      selection => lineItems.some(item => item.id === selection.lineItemId)
        && !itemSelections.some(s => s.id === selection.id)
    );

    const newSelections = await bulkUpdateSelections(
      itemSelections,
      {
        lineItems,
        deleteSelections : removedSelections,
      },
    );

    return newSelections;
  }, [
    selections,
    bulkUpdateSelections,
  ]);

  const removeProductFromCollection = useCallback(async (
    collection : Collection,
    product : Product,
  ) => {
    const newTag = await removeProductFromTag(collection, product);
    if (!newTag || !collection.id) return null;
    return refreshCollection(collection.id);
  }, [removeProductFromTag, refreshCollection]);

  const getProductAssemblies = useCallback((product : Product) => {
    if (!assembies) return [];
    if (customProducts && product.id && customProducts[product.id]) {
      return customProducts[product.id]?.assemblyIds.map((i) => assembies[i])
        .filter((a) => !!a) as Assembly[];
    }
    return listRecords(assembies).filter(
      assembly => product.id && assembly.productIds.includes(product.id)
    )
  }, [customProducts, assembies]);

  const getAssemblyCollections = useCallback((assembly : Assembly) => {
    const now = new Date();
    return collections
      ? listRecords(collections).filter(collection =>
        collection.ending === null
         || collection.ending > now
      ).filter(
        collection => collection.id &&
          assembly.collectionIds.includes(collection.id),
      ).sort((a, b) => {
        if (a.starting === null) return -1;
        if (b.starting === null) return 1;
        return a.starting.getTime() - b.starting.getTime();
      })
      : [];
  }, [collections]);

  const getCollectionWindows = useCallback((
    assembly : Assembly,
    collection : Collection,
  ) => {
    const allCollections = getAssemblyCollections(assembly)
      .filter(col => col.id !== collection.id)
      .filter(col =>
        (col.starting?.getTime() ?? 0 !== collection.starting?.getTime() ?? 0)
          || (col.id ?? Infinity) > (collection.id ?? Infinity)
      )
      .filter(col => (col.starting?.getTime() ?? 0)
        >= (collection.starting?.getTime() ?? 0))
      .sort((a, b) => {
        if (a.starting === null) return 1;
        if (b.starting === null) return -1;
        return a.starting.getTime() - b.starting.getTime();
      }
    )

    const windows = [] as { start : Date | null, end : Date | null }[];
    let current = (collection.starting ?? new Date(0)) as Date | null;
    for (const col of allCollections) {
      if (collection.ending !== null
        && current
        && ((current.getTime() ?? 0) >= collection.ending.getTime())
      ) continue;

      if ((col.starting?.getTime() ?? 0) > (current?.getTime() ?? 0)) {
        windows.push({
          start : current,
          end : col.starting,
        });
      }

      current = col.ending;
      if (!current) break;
    }

    if (
      current
        && (current.getTime() < (collection.ending?.getTime() ?? Infinity))
    ) {
      windows.push({ start : current, end : collection.ending });
    }

    return windows;
  }, [getAssemblyCollections]);

  const getCollectionTags = useCallback((collection : Collection) => {
    return tags
    ? listRecords(tags).filter(
      tag => tag.id && collection.tagIds.includes(tag.id),
      )
      : [];
    }, [tags]);

  const getCollectionProducts = useCallback((
    collection : Collection,
    options? : { includeTags? : boolean },
  ) => {
    options = {
      includeTags : true,
      ...options,
    }

    const collectionProducts = getTagProducts(collection);
    if (options?.includeTags) {
      const collectionTags = getCollectionTags(collection);
      const tagProducts = collectionTags
        .map(tag => getTagProducts(tag)).flat()
        .reduce((acc, val) => {
          if (
            acc.some(p => p.id === val.id)
              || collectionProducts.some(p => p.id === val.id)
          ) return acc;
          return [...acc, val];
        }, [] as Product[]);
      collectionProducts.push(...tagProducts);
    }

    return collectionProducts;
  }, [getCollectionTags, getTagProducts]);

  const getCollectionDefaults = useCallback((collection : Collection) => {
    return collection.defaults.map(
      ({ productId, quantity }) => {
        const product = products && products[productId];
        return product ? {
          product,
          quantity,
        } : null;
      }
    ).filter((p) => !!p);
  }, [products]);

  const getProductDefaultCount = useCallback((
    collection : Collection,
    product : Product,
  ) => {
    return collection.defaults.find(
      ({ productId }) => productId === product.id
    )?.quantity ?? 0;
  }, []);

  const getTotalDefaultCount = useCallback((collection : Collection) => {
    return collection.defaults.reduce((sum, { quantity }) => sum + quantity, 0);
  }, []);

  const getCollection = useCallback((assembly : Assembly, date : Date) => {
    const collections = getAssemblyCollections(assembly).sort(
      (a, b) => {
        if (a.starting === null) return 1;
        if (b.starting === null) return -1;
        return b.starting.getTime() - a.starting.getTime();
      }
    );
    return collections.find((col : Collection) => (
      ((col.starting === null) ||
        (col.starting.getTime() <= date.getTime())) &&
      ((col.ending === null) ||
        (col.ending.getTime() >= date.getTime()))
    ));
  }, [getAssemblyCollections]);

  const getAssemblyProducts = useCallback(
    (assembly : Assembly, date : Date) => {
      const collection = getCollection(assembly, date);
      return collection ? getCollectionProducts(collection) : [];
    },
    [getCollection, getCollectionProducts],
  );

  const moveAssemblyToTop = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const productAssemblies = getProductAssemblies(product);
    const first = productAssemblies[0];
    if (first.id === assembly.id) return;

    return await addAssemblyToProduct(product, assembly, { before : first });
  }, [getProductAssemblies, addAssemblyToProduct]);

  const moveAssemblyToBottom = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const productAssemblies = getProductAssemblies(product);
    const last = productAssemblies[productAssemblies.length - 1];
    if (last.id === assembly.id) return;

    return await addAssemblyToProduct(product, assembly);
  }, [getProductAssemblies, addAssemblyToProduct]);

  const moveAssemblyUp = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const productAssemblies = getProductAssemblies(product);
    const index = productAssemblies.findIndex(a => a.id === assembly.id);
    if ([0, -1].includes(index)) return;

    const before = productAssemblies[index - 1];
    return await addAssemblyToProduct(product, assembly, { before });
  }, [getProductAssemblies, addAssemblyToProduct]);

  const moveAssemblyDown = useCallback(async (
    product : Product,
    assembly : Assembly,
  ) => {
    const productAssemblies = getProductAssemblies(product);
    const index = productAssemblies.findIndex(a => a.id === assembly.id);
    if (index === -1 || index === productAssemblies.length - 1) return;

    const before = productAssemblies[index + 2];
    return await addAssemblyToProduct(product, assembly, { before });
  }, [getProductAssemblies, addAssemblyToProduct]);

  const getAssemblyCounts = useCallback(
    (assembly : Assembly, date : Date) => {
      const collection = getCollection(assembly, date);
      return collection ? {
        min : collection.min,
        max : collection.max,
      } : {
        min : 0,
        max : 0,
      };
    },
    [getCollection],
  );

  const isProductCustomisable = useCallback((
    product : Product,
    date : Date,
  ) => {
    const assemblies = getProductAssemblies(product);
    return assemblies.some(
      assembly => getAssemblyProducts(assembly, date).length > 0
    );
  }, [getProductAssemblies, getAssemblyProducts]);

  const validateOption = useCallback((
    product : Product,
    assembly : Assembly,
    selected : Product,
    date : Date,
  ) => {
    let error = '' as '' | 'invalidAssembly' | 'invalidProduct';

    const assemblies = getProductAssemblies(product);
    if (!assemblies.some(a => a.id === assembly.id)) {
      error = 'invalidAssembly';
      return { valid : false, error };
    }

    const products = getAssemblyProducts(assembly, date);
    if (!products.some(p => p.id === selected.id)) {
      error = 'invalidProduct';
    }

    return { valid : error === '', error };
  }, [getProductAssemblies, getAssemblyProducts]);

  const validateOptions = useCallback((
    product : Product,
    selections : {
      assembly : Assembly
      product: Product,
      quantity : number,
    }[],
    date : Date,
  ) => {
    const errors = {} as {
      [id : number] : {
        key : 'invalidAssembly' | 'invalidProduct' | 'tooFew' | 'tooMany',
        assembly : Assembly,
        product? : Product,
      };
    };

    const assemblies = getProductAssemblies(product);
    for (const assembly of assemblies) {
      if (!assembly.id) continue;
      const count = selections
        .filter((s) => s.assembly.id === assembly.id)
        .reduce((sum, s) => sum + s.quantity, 0);

      const { min, max } = getAssemblyCounts(assembly, date);
      if (!!max && (count > max)) {
        errors[assembly.id] = {
          key : 'tooMany',
          assembly,
        };
        continue;
      }

      if (count < min) errors[assembly.id] = {
        key : 'tooFew',
        assembly,
      };
    }

    for (const { assembly, product : selectedProduct } of selections) {
      if (!assembly.id) continue;
      const { valid, error } = validateOption(
        product,
        assembly,
        selectedProduct,
        date,
      );
      if (!valid && assembly && error) errors[assembly.id] = {
        key : error,
        assembly,
        product : selectedProduct,
      };
    }

    return {
      valid : Object.keys(errors).length === 0,
      errors : errors,
    };
  }, [getProductAssemblies, getAssemblyCounts, validateOption]);

  const validateSelection = useCallback((
    product : Product,
    selection : Selection,
    date : Date,
  ) => {
    let error = '' as '' | 'invalidAssembly' | 'invalidProduct' | 'notFound';

    const assembly = assembies && assembies[selection.assemblyId];
    const selectionProduct = products && products[selection.productId];
    if (!assembly || !selectionProduct) {
      error = 'notFound';
      return { valid : false, error, assembly, product : selectionProduct };
    }

    return validateOption(product, assembly, selectionProduct, date);
  }, [assembies, validateOption, products]);

  const validateSelections = useCallback((
    product : Product,
    selections : Selection[],
    date : Date,
  ) => {
    const errors = {} as { [id : number] : {
      key : 'invalidAssembly'
        | 'invalidProduct'
        | 'tooFew'
        | 'tooMany'
        | 'notFound',
      assembly : Assembly | null,
      product? : Product,
    } };

    const options = [] as {
      assembly : Assembly,
      product : Product,
      quantity : number,
    }[];
    for (const selection of selections) {
      const assembly = assembies && assembies[selection.assemblyId];
      const selectionProduct = products && products[selection.productId];
      if (!assembly || !selectionProduct) {
        errors[selection.assemblyId] = {
          key : 'notFound',
          assembly : assembly ?? null,
        };
        continue;
      }

      options.push({
        assembly,
        product : selectionProduct,
        quantity : selection.quantity,
      });
    }

    const validation = validateOptions(product, options, date);

    return {
      valid : validation.valid && (Object.keys(errors).length === 0),
      errors : { ...errors, ...validation.errors },
    };
  }, [assembies, validateOptions, products]);

  const calculateSelectionPrice = useCallback(
    (selection : Selection) : Currency | null => {
      const assembly = assembies && assembies[selection.assemblyId];
      const product = products && products[selection.productId];
      if (!assembly || !product) return null;

      if (assembly.complimentary) return {
        amount : 0,
        currencyCode : product.price.currencyCode,
        increment : product?.price?.increment,
        calculatedValue : 0,
      };

      return {
        ...product.price,
        amount : product.price.amount * selection.quantity,
        calculatedValue : product.price.amount
          * product.price.increment
          * selection.quantity,
      };
    },
    [assembies, products],
  );

  const generateDefaultOrder = useCallback(() => {
    return {
      ...createDefaultOrder(),
      selections : [],
    } as DraftCustomOrder;
  }, [createDefaultOrder]);

  const findCustomOrder = useCallback(async ({
    address,
    customer,
    serviceChannel,
    location,
    timeSlot,
    iteration,
    division,
  } : {
    address : Address | null,
    customer : Customer | null,
    serviceChannel : ServiceChannel | null,
    location : Location | null,
    timeSlot : TimeSlot | null,
    iteration : number,
    division : number,
  }) => {
    const order = await findOrder({
      address,
      customer,
      serviceChannel,
      location,
      timeSlot,
      iteration,
      division,
    });
    if (!order) return null;
    return customiseOrder(order);
  }, [findOrder, customiseOrder]);

  const getLineItemFulfilment = useCallback((
    lineItem : LineItem,
    order : DraftCustomOrder,
  ) => {
    if (!order.order) return null;
    return listRecords(order.order?.fulfilments).find((f) => (
      f.lineItemId === lineItem.id
        && f.requestedProductId === lineItem.productId
        && !listRecords(order.selections).some(
          (s) => (f.id && s.fulfilmentIds.includes(f.id))
        )
    )) ?? null;
  }, []);

  const getSelectionFulfilment = useCallback((
    selection : Selection,
    order : DraftCustomOrder,
  ) => {
    if (!order.order) return null;

    const lineItem = allLineItems?.[selection.lineItemId];
    return listRecords(order.order?.fulfilments).find((f) => {
      if (f.lineItemId !== selection.lineItemId) return false;

      if (f.id && selection.fulfilmentIds.includes(f.id)) return true;
      if (listRecords(order.selections).some(
        (s) => (f.id && s.fulfilmentIds.includes(f.id))
      )) return false;

      if (lineItem?.productId === f.requestedProductId) return false;

      const preceedingSelections = listRecords(order.selections).filter(
        (s) => s.lineItemId === f.lineItemId
          && (s.id ?? Infinity) < (selection.id ?? Infinity)
      );
      if (preceedingSelections.some(
        (s) => s.productId === f.requestedProductId
      )) return false;

      return f.requestedProductId === selection.productId;
    }) ?? null;
  }, [allLineItems]);

  const listOrphanedFulfilments = useCallback((
    order : DraftCustomOrder,
  ) : {
    fulfilments : Fulfilment[],
    lineItems : LineItem[],
  } => {
    const orderRecord = order.order;
    if (!orderRecord) return ({ fulfilments : [], lineItems : [] });

    const fulfilments = Object.values(orderRecord.fulfilments).filter(
      (fulfilment) => (
        !fulfilment.lineItemId
           || !Object.values(order.lineItems)
            .some((i) => i.id === fulfilment.lineItemId)
      )
    );

    const missingItems = fulfilments.map((fulfilment) => {
      const lineItem = fulfilment.lineItemId
        ? allLineItems?.[fulfilment.lineItemId]
        : undefined;
      return {
        ...lineItem ?? orderRecord,
        id : lineItem?.id,
        productId : fulfilment.fulfilledProductId
          ?? fulfilment.requestedProductId,
        quantity : fulfilment.fulfilledQty ?? fulfilment.requestedQty,
        price : fulfilment.price,
        guestCode : '',
      };
    });

    return {
      fulfilments,
      lineItems : missingItems,
    };
  }, [allLineItems]);

  const fillCollection = useCallback((
    collection : Collection,
    curentCounts : { [id : number] : number },
    minCount : number,
  ) => {
    const fill = [] as {
      productId : number;
      qty : number;
    }[];
    const counts = { ...curentCounts };
    let remaining = minCount;

    while (remaining > 0) {
      const defaults = getCollectionDefaults(collection);
      const products = getCollectionProducts(collection);

      let productId = null as number | null;
      let qty = 0;
      for (const def of defaults) {
        const id = def.product.id;
        if (!id) continue;
        const selected = counts[id] ?? 0;
        if (selected >= def.quantity) continue;
        productId = id;
        qty = Math.min(remaining, def.quantity - selected);
        break;
      }
      if (!productId) {
        for (const prod of products) {
          const id = prod.id;
          if (!id) continue;
          const selected = counts[id] ?? 0;
          if (selected >= 1) continue;
          productId = id;
          qty = 1;
          break;
        }
      }
      if (!productId) {
        productId = products[0]?.id ?? null;
        qty = remaining;
      }
      if (!productId) break;

      remaining -= qty;
      counts[productId] = (qty + (counts[productId] ?? 0));
      fill.push({ productId, qty });
    }

    return fill;
  }, [getCollectionDefaults, getCollectionProducts]);

  const resolveSelections = useCallback((
    lineItem : LineItem,
    order : DraftCustomOrder,
  ) => {
    const time = order.time ?? new Date();
    const product = products && products[lineItem.productId];
    if (!product || !isProductCustomisable(product, time)) return null;

    const assembies = getProductAssemblies(product);

    const validItemSelections = Object.values(order.selections).filter(
      selection => selection.lineItemId === lineItem.id
        && assembies.some(a => a.id === selection.assemblyId)
    );
    const invalidItemSelections = Object.values(order.selections).filter(
      selection => selection.lineItemId === lineItem.id
        && !assembies.some(a => a.id === selection.assemblyId)
    );

    const claimedFulfilments = [] as Fulfilment[];
    const result = [] as {
      selection : Selection | null,
      fulfilment : Fulfilment | null,
      assembly : Assembly | null,
      valid : boolean,
    }[];
    for (const assembly of assembies) {
      const { min } = getAssemblyCounts(assembly, time);
      const collection = getCollection(assembly, time);
      const assemblySelections = validItemSelections
        .filter(s => s.assemblyId === assembly.id);

      const counts : { [id : number] : number } = {};
      const selectionForReassignment = [] as Selection[];
      for (const selection of assemblySelections) {
        let fulfilment = getSelectionFulfilment(selection, order);
        if (fulfilment) claimedFulfilments.push(fulfilment);

        if (fulfilment?.fulfilledProductId) {
          counts[fulfilment?.fulfilledProductId] = (
            (fulfilment.fulfilledQty ?? fulfilment.requestedQty)
              + (counts[fulfilment.fulfilledProductId] ?? 0)
          );
          result.push({ selection, fulfilment, assembly, valid : true });
          continue;
        }

        const { valid } = validateSelection(product, selection, time);
        if (valid) {
          if (!fulfilment) {
            const selectionProduct = products[selection.productId];
            if (selectionProduct) {
              fulfilment = generateDefaultFulfilment({
                order,
                product : selectionProduct,
              });
            }
            if (fulfilment) {
              fulfilment.lineItemId = lineItem.id ?? null;
              const price = calculateSelectionPrice(selection);
              if (price) {
                fulfilment.unitPrice = price;
                fulfilment.price = price;
              }
            }
          } else {
            fulfilment = { ...fulfilment };
          }

          counts[selection.productId] = (
            (fulfilment?.fulfilledQty ?? selection.quantity)
              + (counts[selection.productId] ?? 0)
          );
          result.push({ selection, fulfilment, assembly, valid : true });
          continue;
        }

        if (!assembly.automatic) {
          result.push({ selection, fulfilment, assembly, valid : false });
          continue;
        }

        selectionForReassignment.push(selection);
      }

      const assemblyProducts = getAssemblyProducts(assembly, time);
      const extraFulfilments = order.order
        ? Object.values(order.order.fulfilments).filter((f) => (
          !claimedFulfilments.some((c) => c.id === f.id)
            && f.lineItemId === lineItem.id
            && assemblyProducts.some((p) => p.id === f.requestedProductId)
            && f.requestedProductId !== lineItem.productId
            && !validItemSelections.some((s) => f.id
              && s.fulfilmentIds.includes(f.id))
        )) : [];
      for (const fulfilment of extraFulfilments) {
        const productId = fulfilment.fulfilledProductId
          ?? fulfilment.requestedProductId;

        const remaining = min
          - Object.values(counts).reduce((acc, c) => acc + c, 0);
        const f = (fulfilment.fulfilledQty !== null)
          ? fulfilment
          : {
            ...fulfilment,
            fulfilledQty : (remaining > 0)
              ? Math.min(remaining, fulfilment.requestedQty)
              : 0,
          }

        counts[productId] = (f.fulfilledQty ?? f.requestedQty)
          + (counts[productId] ?? 0);
        claimedFulfilments.push(f);
        result.push({
          selection : null,
          fulfilment : f,
          assembly,
          valid : true,
        });
      }

      for (const selection of selectionForReassignment) {
        const remaining = min
          - Object.values(counts).reduce((acc, c) => acc + c, 0);

        let fulfilment = getSelectionFulfilment(selection, order)
        if (!fulfilment) {
          const selectionProduct = products[selection.productId];
          if (selectionProduct) {
            fulfilment = generateDefaultFulfilment({
              order,
              product : selectionProduct,
            });
          }
        } else {
          fulfilment = { ...fulfilment };
        }

        if (!collection) continue;
        const fill = fillCollection(
          collection,
          counts,
          remaining,
        );
        const productId = fill[0]?.productId;
        const qty = fill[0]?.qty;
        if (!productId) {
          if (fulfilment) {
            fulfilment.lineItemId = lineItem.id ?? null;
            fulfilment.fulfilledQty = 0;
            const price = calculateSelectionPrice({
              ...selection,
              quantity : 0,
            }) ?? { ...product.price, amount : 0, calculatedValue : 0 };
            fulfilment.unitPrice = price;
            fulfilment.price = price;
          }
          result.push({ selection, fulfilment, assembly, valid : false });
          continue;
        }

        if (remaining <= 0) {
          if (fulfilment) {
            fulfilment.lineItemId = lineItem.id ?? null;
            fulfilment.fulfilledProductId = productId;
            fulfilment.fulfilledQty = 0;
            const price = {
              ...product.price,
              amount : 0,
              calculatedValue : 0,
            };
            fulfilment.unitPrice = price;
            fulfilment.price = price;
          }
          result.push({ selection, fulfilment, assembly, valid : false });
          continue;
        }

        if (fulfilment) {
          fulfilment.lineItemId = lineItem.id ?? null;
          fulfilment.fulfilledProductId = productId;
          fulfilment.fulfilledQty = qty;
          const price = calculateSelectionPrice({
            ...selection,
            productId,
            quantity : qty,
          }) ?? multiplyCurrency(product.price, assembly.complimentary ? 0 : 1);
          fulfilment.unitPrice = price;
          fulfilment.price = multiplyCurrency(price, qty);
        }
        counts[productId] = (qty + (counts[productId] ?? 0));
        result.push({ selection, fulfilment, assembly, valid : false });
      }

      if (!assembly.automatic || !collection) continue;

      for (const selection of invalidItemSelections) {
        const fulfilment = getSelectionFulfilment(selection, order);
        if (!fulfilment) continue;
        if (claimedFulfilments.some((f) => f.id === fulfilment?.id)) continue;

        const fulfilmentProductId = fulfilment.fulfilledProductId
          ?? fulfilment.requestedProductId;
        if (assemblyProducts.some((p) => p.id === fulfilmentProductId)) {
          claimedFulfilments.push(fulfilment);
          counts[fulfilmentProductId] = (
            (fulfilment.fulfilledQty ?? fulfilment.requestedQty)
              + (counts[fulfilmentProductId] ?? 0)
          );
          result.push({ selection : null, fulfilment, assembly, valid : true });
          continue;
        }

        const remaining = min
          - Object.values(counts).reduce((acc, c) => acc + c, 0);
        const fill = fillCollection(
          collection,
          counts,
          remaining,
        );
        const productId = fill[0]?.productId;
        const qty = fill[0]?.qty;

        if (!productId) continue;
        const selectionProduct = products[productId];
        if (!selectionProduct) continue;

        const f = { ...fulfilment };
        f.lineItemId = lineItem.id ?? null;
        f.fulfilledProductId = productId;
        f.fulfilledQty = qty;
        const price = (
          (lineItem.id && assembly.id)
            ? calculateSelectionPrice({
              lineItemId : lineItem.id,
              assemblyId : assembly.id,
              productId,
              quantity : qty,
              fulfilmentIds : [],
            })
            : null
        ) ?? multiplyCurrency(product.price, assembly.complimentary ? 0 : 1);
        f.unitPrice = price;
        f.price = multiplyCurrency(price, qty);

        claimedFulfilments.push(f);
        counts[productId] = (qty + (counts[productId] ?? 0));
        result.push({ selection, fulfilment : f, assembly, valid : true });
      }

      const remaining = min
        - Object.values(counts).reduce((acc, c) => acc + c, 0);
      if (remaining <= 0) continue;

      const fill = fillCollection(
        collection,
        counts,
        remaining,
      );
      for (const { productId, qty } of fill) {
        if (qty <= 0) continue;
        const selectionProduct = products[productId];
        if (!selectionProduct) continue;

        const fulfilment = generateDefaultFulfilment({
          order,
          product : selectionProduct,
        });
        if (fulfilment) {
          fulfilment.lineItemId = lineItem.id ?? null;
          fulfilment.fulfilledProductId = productId;
          fulfilment.fulfilledQty = qty;
          const price = (
            (lineItem.id && assembly.id)
              ? calculateSelectionPrice({
                lineItemId : lineItem.id,
                assemblyId : assembly.id,
                productId,
                quantity : qty,
                fulfilmentIds : [],
              })
              : null
          ) ?? multiplyCurrency(product.price, assembly.complimentary ? 0 : 1);
          fulfilment.unitPrice = price;
          fulfilment.price = multiplyCurrency(price, qty);
        }
        result.push({ selection : null, fulfilment, assembly, valid : true });
      }
    }

    const remainingFulfilments = order.order
      ? Object.values(order.order.fulfilments).filter((f) => (
        (f.lineItemId === lineItem.id)
          && (f.requestedProductId !== lineItem.productId)
          && !result.some((r) => r.fulfilment?.id === f.id)
      )) : [];
    for (const fulfilment of remainingFulfilments) {
      result.push({
        selection : null,
        fulfilment : {
          ...fulfilment,
          fulfilledQty : fulfilment.fulfilledQty ?? 0,
        },
        assembly : null,
        valid : false,
      });
    }

    result.sort((a, b) => {
      return (a.assembly?.id !== b.assembly?.id)
        ? (a.assembly?.id ?? Infinity) - (b.assembly?.id ?? Infinity)
        : (a.selection?.id !== b.selection?.id)
          ? (a.selection?.id ?? Infinity) - (b.selection?.id ?? Infinity)
          : (a.fulfilment?.id ?? Infinity) - (b.fulfilment?.id ?? Infinity);
    });

    return result;
  }, [
    products,
    isProductCustomisable,
    getProductAssemblies,
    getCollection,
    getAssemblyProducts,
    getAssemblyCounts,
    validateSelection,
    calculateSelectionPrice,
    getSelectionFulfilment,
    fillCollection,
    generateDefaultFulfilment,
  ]);

  const validateCustomItem = useCallback((
    lineItem : LineItem,
    order? : DraftCustomOrder | null,
  ) => {
    const errors = {} as { [id : number] : {
      key : 'invalidAssembly'
        | 'invalidProduct'
        | 'tooFew'
        | 'tooMany'
        | 'notFound',
      assembly : Assembly | null,
      product? : Product,
    } };

    const time = order?.time ?? new Date();

    const product = products && products[lineItem.productId];
    if (!product) return null;

    if (!order) {
      const product = products && products[lineItem.productId];
      if (!product || !selections) return null;
      const itemSelections = listRecords(selections)
        .filter((s) => s.lineItemId === lineItem.id);
      return validateSelections(product, itemSelections, time);
    }

    const resolved = resolveSelections(lineItem, order);
    if (!resolved) return null;

    const options = [] as {
      assembly : Assembly,
      product : Product,
      quantity : number,
    }[];

    for (const { selection, fulfilment, assembly } of resolved) {
      const quantity = fulfilment?.fulfilledQty
        ?? fulfilment?.requestedQty
        ?? selection?.quantity
        ?? 0;
      if (quantity <= 0) continue;

      if (!assembly) {
        const selectionProductId = fulfilment?.fulfilledProductId
          ?? fulfilment?.requestedProductId
          ?? selection?.productId
          ?? null
        const selectionProduct = selectionProductId !== null
          ? products[selectionProductId]
          : null;
        errors[-1] = {
          key : 'invalidAssembly',
          assembly : null,
          product : selectionProduct ?? undefined,
        };
        continue;
      }

      if (!assembly.id) continue;
      const selectedProductId = fulfilment?.fulfilledProductId
        ?? fulfilment?.requestedProductId
        ?? selection?.productId
        ?? null
      const selectedProduct = selectedProductId !== null
        ? products[selectedProductId]
        : null;

      if (!selectedProduct) {
        errors[assembly.id] = { key : 'notFound', assembly };
        continue;
      }

      options.push({
        assembly,
        product : selectedProduct,
        quantity,
      });
    }

    const validation = validateOptions(
      product,
      options.filter((o) => !!o.quantity),
      time,
    );

    return {
      valid : validation.valid && (Object.keys(errors).length === 0),
      errors : { ...errors, ...validation.errors },
    };
  }, [
    products,
    selections,
    resolveSelections,
    validateOptions,
    validateSelections,
  ]);

  const canEditItem = useCallback((
    order : DraftCustomOrder,
    lineItem : LineItem,
  ) => {
    return (
      !order.order
        || !Object.values(order.order.fulfilments).some(
          (fulfilment) => fulfilment.lineItemId === lineItem.id
        )
    );
  }, []);

  const loadOptions = useCallback(() => {
    load();
    loadProducts();
    loadTags();
    loadOrders();
  }, [load, loadProducts, loadTags, loadOrders]);

  return {
    ...contextHooks,
    loaded : contextHooks.assembliesLoaded
      && contextHooks.collectionsLoaded
      && contextHooks.selectionsLoaded
      && productsLoaded
      && tagsLoaded
      && ordersLoaded,
    load : loadOptions,
    createProductAssembly,
    moveAssemblyToTop,
    moveAssemblyToBottom,
    moveAssemblyUp,
    moveAssemblyDown,
    createAssemblyCollection,
    addProductToCollection,
    removeProductFromCollection,
    getProductAssemblies,
    getAssemblyCollections,
    getCollection,
    getCollectionWindows,
    getCollectionProducts,
    getCollectionTags,
    getCollectionDefaults,
    getProductDefaultCount,
    getTotalDefaultCount,
    getAssemblyProducts,
    getAssemblyCounts,
    findCustomOrder,
    isProductCustomisable,
    validateSelection,
    validateSelections,
    validateCustomItem,
    calculateSelectionPrice,
    /** @deprecated */
    bulkCreateCustomisedLineItems : bulkCreateSelections,
    bulkUpdateCustomisedLineItems,
    createDefaultOrder : generateDefaultOrder,
    getLineItemFulfilment,
    getSelectionFulfilment,
    listOrphanedFulfilments,
    canEditItem,
    generateDefaultAssembly,
    generateDefaultCollection,
    resolveSelections,
    draftEmptyOrder : draftEmptyCustomOrder,
  }
}

export default useOptions;
