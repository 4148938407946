import {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import {
  DataReducer,
  DataIndex,
  DataStore,
  DataAction,
} from '#mrktbox/clerk/types';
import {
  reduceDataStore,
  getDataStore as getLocalStorage,
  setDataStore as setLocalStorage,
} from '#mrktbox/clerk/hooks/useStorage';
import { actionTypes } from '#mrktbox/clerk/utils/data';

async function getDataStore<RT>({
  storageKey,
  deserializer,
} : {
  storageKey : string,
  deserializer? : (data : any) => RT | null,
}) : Promise<DataStore<RT> | null> {
  if (!window.indexedDB) return getLocalStorage({ storageKey, deserializer });

  const result = await (new Promise<{
    store : DataStore<RT> | null,
    invalidDb? : boolean,
  }>(async (resolve) => {
    let db = null as IDBDatabase | null;
    let newDatabase = false;
    const recovered = getLocalStorage({ storageKey, deserializer });

    const connect = window.indexedDB.open(`BatchedCache-${storageKey}`, 1);
    connect.onerror = () => {
      console.warn(`Database connection failed (${storageKey})`);
      resolve({ store : getLocalStorage({ storageKey, deserializer }) });
    };
    connect.onupgradeneeded = () => {
      db = connect.result;
      db.createObjectStore('data');
      db.createObjectStore('timestamp');
      newDatabase = true;
    }
    connect.onsuccess = () => {
      db = connect.result;

      let transaction = null as IDBTransaction | null;
      try {
        transaction = db.transaction(
          ['data', 'timestamp'],
          (newDatabase && recovered) ? 'readwrite' : 'readonly',
        );
      } catch {
        transaction = null;
      }
      if (!transaction) {
        db.close();
        resolve({
          store : getLocalStorage({ storageKey, deserializer }),
          invalidDb : true,
        });
        return;
      }

      const dataStore = transaction.objectStore('data');
      const timestampStore = transaction.objectStore('timestamp');

      if (newDatabase) {
        if (recovered) {
          for (const key in recovered.data) {
            dataStore.put(recovered.data[key], key);
          }
          timestampStore.put(recovered.timestamp, 'last');
          transaction.oncomplete = () => resolve({ store : recovered });
          transaction.onerror = () => {
            console.warn(`Database recovery failed (${storageKey})`);
            resolve({ store : recovered });
          };
        } else resolve({ store : { data : {}, storageKey } });
      } else {
        const dataRequest = dataStore.getAll();
        const timestampRequest = timestampStore.get('last');

        let data = null as any;
        let timestamp = null as any;
        dataRequest.onsuccess = () => {
          data = dataRequest.result.reduce((acc, i) => {
            if (!i?.id) return acc;
            return { ...acc, [i.id] : i }
          }, {});
        };
        timestampRequest.onsuccess = () => {
          timestamp = timestampRequest.result;
        };

        transaction.oncomplete = () => resolve({
          store : { data, timestamp, storageKey },
        });
        transaction.onerror = () => {
          console.warn(`Database retrieve failed (${storageKey})`);
          resolve({ store : getLocalStorage({ storageKey, deserializer }) });
        };
      }
    }
  }));

  if (result.invalidDb)clearDataStores({ storageKey });
  return result.store;
}

async function setDataStore<RT>({
  store,
  serializer,
} : {
  store : DataStore<RT>,
  serializer? : (data : RT) => any,
}) : Promise<void> {
  if (!window.indexedDB) {
    setLocalStorage({ store, serializer });
    return;
  }

  return new Promise((resolve) => {
    let db = null as IDBDatabase | null;
    const connect = window.indexedDB
      .open(`BatchedCache-${store.storageKey}`, 1);
    connect.onerror = () => {
      console.warn(`Database connection failed (${store.storageKey})`);
      setLocalStorage({ store, serializer });
      resolve();
    };
    connect.onupgradeneeded = () => {
      console.warn(`Database not initialized (${store.storageKey})`);
      setLocalStorage({ store, serializer });
      resolve();
    }
    connect.onsuccess = () => {
      db = connect.result;

      let transaction = null as IDBTransaction | null;
      try {
        transaction = db.transaction(['data', 'timestamp'], 'readwrite');
      } catch {
        transaction = null;
      }
      if (!transaction) {
        db.close();
        setLocalStorage({ store, serializer });
        resolve();
        return;
      }

      const dataStore = transaction.objectStore('data');
      const timestampStore = transaction.objectStore('timestamp');

      const clearRequest = dataStore.clear();
      clearRequest.onsuccess = () => {
        for (const key in store.data) { dataStore.put(store.data[key], key); }
        timestampStore.put(store.timestamp, 'last');
      }

      transaction.oncomplete = () => { resolve(); };
      transaction.onerror = () => {
        console.warn(`Database update failed (${store.storageKey})`);
        setLocalStorage({ store, serializer });
        resolve();
      };
    }
  });
}

async function clearDataStores({
  storageKey,
} : { storageKey? : string } = {}) : Promise<void> {
  if (!window.indexedDB) return;

  if (!storageKey) {
    try {
      const databases = await window.indexedDB.databases();
      for (const { name } of databases) {
        if (name?.startsWith('BatchedCache-')) {
          await clearDataStores({ storageKey : name.slice(13) });
        }
      }
    } catch {
      console.warn('Database clear failed');
    }
    return;
  }

  return new Promise((resolve) => {
    const connect = window.indexedDB
      .deleteDatabase(`BatchedCache-${storageKey}`);
    connect.onerror = () => {
      console.warn(`Database clear failed (${storageKey})`);
      resolve();
    };
    connect.onsuccess = () => { resolve(); };
  });
}

interface useIndexDBProps<RT> {
  initData? : DataIndex<RT> | null;
  storageKey? : string;
  serializer? : (data : RT) => any;
  deserializer? : (data : any) => RT | null;
}

function useIndexedDB<RT>({
  initData,
  storageKey,
  serializer,
  deserializer,
} : useIndexDBProps<RT> = {}) {
  const initiated = useRef(false);
  const initialized = useRef(false);
  const updating = useRef(false);
  const needsUpdate = useRef(false);
  const [chainUpdate, setChainUpdate] = useState(false);
  const [key] = useState(storageKey ?? '');

  const [dataStore, dispatchData] = useReducer<DataReducer<RT>>(
    reduceDataStore<RT>,
    { storageKey : key ?? '', data : initData ?? {} },
  );

  const initializeCache = useCallback(async () => {
    if (key) {
      const store = await getDataStore<RT>({
        storageKey : key,
        deserializer,
      });
      if (!store) return;

      dispatchData({
        data: store.data,
        type : actionTypes.set,
        timestamp : store.timestamp ?? null,
      });
    }
    setTimeout(() => { initialized.current = true; }, 10);
  }, [deserializer, key]);

  const updateCache = useCallback(async (store : DataStore<RT>) => {
    if (!key || !initialized.current) return;
    await setDataStore({ store : { ...store, storageKey : key }, serializer });
  }, [serializer, key]);

  const clearCache = useCallback(() => {
    if (key) {
      dispatchData({ data : {}, type : actionTypes.clear });
      return;
    }
    clearDataStores();
  }, [key]);

  const tryDispatch = useCallback((action : DataAction<RT>) => {
    if (!initialized.current) {
      console.warn('Cache dispatched before initialization:', key);
      return;
    };
    dispatchData(action);
  }, [key]);

  useEffect(() => {
    if (initiated.current) return;
    initiated.current = true;
    initializeCache();
  }, [initializeCache]);

  useEffect(() => {
    setChainUpdate(false);
    if (!key) return;
    if (updating.current) return;

    needsUpdate.current = false;
    if (dataStore === null) return;
    updating.current = true;

    updateCache({ ...dataStore, storageKey : key});

    setTimeout(() => {
      if (needsUpdate.current) setChainUpdate(true);
      updating.current = false;
    }, 30000);
  }, [key, chainUpdate, dataStore, updateCache]);

  useEffect(() => {
    if (updating.current) needsUpdate.current = true;
  }, [dataStore]);

  useEffect(() => {
    if ((storageKey ?? '') === key) return;
    console.error('useIndexDB: storageKey cannot be changed');
  }, [storageKey, key]);

  return {
    data : initialized.current ? (dataStore?.data ?? null) : null,
    lastUpdated : dataStore?.timestamp,
    dispatch : tryDispatch,
    clearCache,
  };
}

export default useIndexedDB;
