
export interface RequestOptions {
  token : string;
}
export type ResponseStatus = 'success'
  | 'bad'
  | 'unauthorized'
  | 'forbidden'
  | 'notFound'
  | 'conflict'
  | 'error';

export type Request<TIn, TOut> = (
  input : TIn,
  options? : RequestOptions
) => Promise<TOut>;

export type ResponseReturns = {
  status : ResponseStatus;
  errorKey? : string;
};

export interface Response<TOut> extends ResponseReturns {
  response : TOut | null;
};
export type ResponseWithReturns<TOut> = [TOut | null, ResponseReturns];

export interface ResponseError {
  code : number;
  message : string;
  reason? : string;
}

export function isResponseError(error : any) : error is ResponseError {
  return (
    error
      && typeof error === 'object'
      && 'code' in error
  );
}
