import { useCallback, useContext } from "react";

import {
  RequestOptions,
  Request,
  Response,
  ResponseReturns,
} from '#mrktbox/clerk/types';

import { APIError } from '#mrktbox/clerk/api';

import AuthContext from '#mrktbox/clerk/context/AuthContext';

import { responseStatus } from '#mrktbox/clerk/utils/api';

export { responseStatus }  from '#mrktbox/clerk/utils/api';

function getStatusFromCode(code : number) {
  switch (code) {
    case 400: return responseStatus.bad;
    case 401: return responseStatus.unauthorized;
    case 403: return responseStatus.forbidden;
    case 404: return responseStatus.notFound;
    case 409: return responseStatus.conflict;
    case 500: return responseStatus.error;
    case 503: return responseStatus.error;
    default: return responseStatus.success;
  }
}

export function useResponse<
  Args extends any[],
  TP,
>(
  process : (...args : Args) => Promise<Response<TP | null>>,
) {
  return useCallback(async (...input : Args) : Promise<TP | null> => {
    return (await process(...input))?.response;
  }, [process]);
}

function generateResponse<TIn, TOut extends Response<TP | null>, TP = any>(
  wrapper : (request : Request<TIn, TP>, input : TIn) => Promise<TP | null>,
) : (
  request : Request<TIn, TP>,
  input : TIn,
) => Promise<TOut> {
  return async (
    request :  Request<TIn, TP>,
    input : TIn,
  ) => {
    // listen for APIErrors, before they are caught by wrapper
    let error = null as APIError | null;
    function middleware(request : Request<TIn, TP>) {
      return async (input : TIn, options? : RequestOptions) => {
        try {
          return await request(input, options);
        } catch (e) {
          if (e instanceof APIError) error = e;
          throw e;
        }
      }
    }

    try {
      const response = await wrapper(middleware(request), input);
      return {
        response : response,
        status : getStatusFromCode(error ? (error.code ?? 500) : 200),
        errorKey : error?.key,
      } as TOut;
    } catch (e) {
      if (e instanceof APIError) {
        return {
          response : null,
          status : getStatusFromCode(e.code ?? 500),
          errorKey : e.key,
        } as TOut;
      } else throw e;
    }
  }
}

function useAPI() {
  const { token, clear } = useContext(AuthContext);

  const processRequest = useCallback(
    async <TIn, TOut>(
      request : Request<TIn, TOut>,
      input : TIn,
    ) => {
      try {
        return await request(
          input,
          { token : token ?? '' }
        );
      } catch (e) {
        if (e instanceof APIError) {
          console.error(e.code, e.message);
          if (e.code === 401) clear();
          return null;
        } else throw e;
      }
    },
    [token, clear],
  )

  const processRequestWithFeeback = useCallback(
    async <TIn, TOut>(
      proccess : (input : TIn) => Promise<TOut>,
      input : TIn,
    ) => {
      const getResponse = generateResponse<TIn, Response<TOut>>(processRequest);
      return getResponse(proccess, input);
    },
    [processRequest],
  )

  const processRequestWithReturns = useCallback(
    async <TIn, TOut>(
      proccess : (input : TIn) => Promise<TOut>,
      input : TIn,
    ) => {
      const {
        response,
        ...returns
      } = await processRequestWithFeeback(proccess, input);
      return [response, returns] as [TOut | null, ResponseReturns];
    },
    [processRequestWithFeeback],
  )

  return {
    processRequest,
    processRequestWithFeeback,
    processRequestWithReturns,
  };
}

export default useAPI;
