
const clerkGlobals = {
  config: {
    domain : '',
  },
  settings : {
    projections : {
      backProjection : 1000 * 60 * 60 * 24 * 7 * 2,
      forwardProjection : 1000 * 60 * 60 * 24 * 7 * 4,
    },
  }
};

export function getConfig() : typeof clerkGlobals.config {
  try {
    const config = localStorage.getItem('clerkConfig');
    return config ? JSON.parse(config) : clerkGlobals.config;
  } catch {
    return clerkGlobals.config;
  }
}

export function getSettings() : typeof clerkGlobals.settings {
  try {
    const settings = localStorage.getItem('clerkSettings');
    return settings ? JSON.parse(settings) : clerkGlobals.settings;
  } catch {
    return clerkGlobals.settings;
  }
}

export function setConfig(config : typeof clerkGlobals.config) {
  clerkGlobals.config = config;
  try {
    localStorage.setItem('clerkConfig', JSON.stringify(config));
  } catch { }
}

export function setSettings(settings : typeof clerkGlobals.settings) {
  clerkGlobals.settings = settings
  try {
    localStorage.setItem('clerkSettings', JSON.stringify(settings));
  } catch { }
}

export function getApiDomain() : string {
  const config = getConfig();
  return (config.domain || process.env.REACT_APP_DOMAIN) ?? '';
}
