import React, { useCallback, useEffect, useState } from 'react';

import {
  Currency,
  Product,
  Category,
  DraftCustomOrder,
} from '#types';

import { FormProvider } from '#context/FormContext';

import useForm from '#hooks/useForm';
import useProducts from '#hooks/useProducts';
import useCategories from '#hooks/useCategories';
import useOptions from '#hooks/useOptions';

import { settings } from '#materials';
import Form from '#materials/Form';
import Select from '#materials/Select';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeFormKeys = locale.keys.forms.orders;

export interface ItemResult {
  id? : number;
  productId : number;
  quantity : number;
  price : Currency | null;
}

interface FormState {
  category? : Category | null;
  product? : Product | null;
}

interface ItemSearchProps extends FormState {
  orders? : DraftCustomOrder[] | null;
  disabled? : boolean;
  setProduct? : (product : Product | null) => void;
  setCategory? : (category : Category | null) => void;
  setOrders? : (orders : DraftCustomOrder[]) => void;
  setLineItems? : (lineItems : ItemResult[]) => void;
}

function ItemSearchControl({
  orders,
  setProduct,
  setCategory,
  setLineItems,
  setOrders,
  disabled = false,
  ...props
} : ItemSearchProps) {
  const { state, dispatch } = useForm<FormState>();

  const product = state?.product ?? props.product ?? null;
  const category = state?.category ?? props.category ?? null;

  const { products : allProducts } = useProducts();
  const { categories : allCategories } = useCategories();
  const {
    customOrders : allOrders,
    resolveSelections,
    getLineItemFulfilment,
  } = useOptions();
  orders = orders ?? allOrders;

  const [products, setProducts] = useState<Product[]>(
    listRecords(allProducts),
  );
  const [categories, setCategories] = useState<Category[]>(
    listRecords(allCategories),
  );

  const dispatchProduct = useCallback((value : Product | null) => {
    dispatch({ product : value });
    if (setProduct) setProduct(value);
  }, [dispatch, setProduct]);
  const dispatchCategory = useCallback((value : Category | null) => {
    dispatch({ category : value });
    if (setCategory) setCategory(value);
  }, [dispatch, setCategory]);

  useEffect(() => { setProducts(listRecords(allProducts)); }, [allProducts]);
  useEffect(() => {
    setCategories(listRecords(allCategories));
  }, [allCategories]);

  useEffect(() => {
    if (!allProducts) return;
    if (category) {
      const categoryProducts = category.productIds
        .map((id) => allProducts[id])
        .filter((product) => !!product) as Product[];
      setProducts(categoryProducts);
      return;
    }
    setProducts(listRecords(allProducts));
  }, [category, allProducts]);

  useEffect(() => {
    if (!orders) {
      setOrders?.([]);
      return;
    }

    const matchingItems : ItemResult[] = [];
    const matchingOrders : DraftCustomOrder[] = [];
    for (const order of orders) {
      let matchingOrder = false;
      for (const lineItem of Object.values(order.lineItems)) {
        const fulfilment = getLineItemFulfilment(lineItem, order);
        if (fulfilment?.status === 'cancelled') continue;

        const productId = fulfilment?.fulfilledProductId
          ?? fulfilment?.requestedProductId
          ?? lineItem.productId;
        if (
          (!product?.id || productId === product.id)
            && (!category || category.productIds.includes(productId))
        ) {
          matchingItems.push(lineItem);
          matchingOrder = true;
          continue;
        }

        const resolvedSelections = resolveSelections(lineItem, order) ?? [];
        for (const resolution of resolvedSelections) {
          const selectionProductId = resolution.fulfilment?.fulfilledProductId
            ?? resolution.fulfilment?.requestedProductId
            ?? resolution.selection?.productId;
          if (!selectionProductId) continue;
          if (
            (!product?.id || selectionProductId === product.id)
              && (!category || category.productIds.includes(selectionProductId))
          ) {
            matchingItems.push(lineItem);
            matchingOrder = true;
            break;
          }
        }
      }

      const fulfilments = order.order
        ? Object.values(order.order.fulfilments)
        : [];
      const remainingFulfilments = fulfilments.filter((f) => (
        !Object.values(order.lineItems).some((li) => (li.id === f.lineItemId))
      ));

      for (const fulfilment of remainingFulfilments) {
        const productId = fulfilment.fulfilledProductId
          ?? fulfilment.requestedProductId;
        if (
          (!product?.id || productId === product.id)
            && (!category || category.productIds.includes(productId))
        ) {
          matchingItems.push({
            productId : productId,
            quantity : fulfilment.fulfilledQty ?? fulfilment.requestedQty,
            price : fulfilment.unitPrice,
          });
          matchingOrder = true;
        }
      }

      if (matchingOrder) matchingOrders.push(order);
    }

    setLineItems?.(matchingItems);
    setOrders?.(matchingOrders);
  }, [
    product,
    category,
    orders,
    setOrders,
    setLineItems,
    getLineItemFulfilment,
    resolveSelections,
  ]);

  const error = !!(product?.id && category)
    && !category.productIds.includes(product.id)

  return (
    <>
      <Form>
        <Select
          label={localize(localeFormKeys.labels.category)}
          selected={category}
          onChange={dispatchCategory}
          options={categories}
          errors={error
            ? [localize(localeFormKeys.errors.productCategoryMismatch)]
            : []}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={(category) => category?.name ?? ''}
          keyGenerator={(category) => `${category?.id}`}
          width={settings.dimensions.half}
          disabled={disabled}
        />
        <Select
          label={localize(localeFormKeys.labels.product)}
          selected={product}
          onChange={dispatchProduct}
          options={products}
          isEqual={(a, b) => a?.id === b?.id}
          labelGenerator={(product) => product?.name ?? ''}
          keyGenerator={(product) => `${product?.id}`}
          width={settings.dimensions.half}
          disabled={disabled}
        />
      </Form>
    </>
  );
}

function ItemSearch(props : ItemSearchProps) {
  return (
    <FormProvider init={props}>
      <ItemSearchControl {...props}/>
    </FormProvider>
  );
}

export default ItemSearch;
