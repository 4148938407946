import { useCallback, useContext } from 'react';

import { Customer, CreditCard } from '#mrktbox/clerk/types';

import CardsContext from '#mrktbox/clerk/context/CardsContext';

import { generateUrl } from '#mrktbox/clerk/utils/nav';
import { listRecords } from '#mrktbox/clerk/utils/data';

function useCards() {
  const {
    creditCards,
    createCreditCard,
    ...context
  } = useContext(CardsContext);

  const createCreditCardWithValidation = useCallback(
    async (
      creditCard : CreditCard,
      customer : Customer,
      validation : {
        token : string,
        postal? : string,
      } | string,
      postal? : string,
    ) => {
      const token = typeof validation === 'string'
        ? validation
        : validation.token;
      const postalCode = typeof validation === 'string'
        ? postal
        : validation.postal;
      if (!token) return null;
      return await createCreditCard(
        creditCard,
        customer,
        { token, postal : postalCode ?? '' },
      );
    },
    [createCreditCard],
  );

  const getCustomerCreditCards = useCallback((customer : Customer) => {
    return listRecords(creditCards).filter(
      (creditCard) => creditCard.customerId === customer.id,
    );
  }, [creditCards]);

  const generateRegistrationLink = useCallback((customer : Customer) =>{
    const { id, defaultName : name, defaultEmail : email } = customer;
    return generateUrl(
      '/cards/register/',
      { customerId : id, email, name }
    );
  }, []);

  return {
    ...context,
    creditCards,
    createCreditCard : createCreditCardWithValidation,
    getCustomerCreditCards,
    generateRegistrationLink,
  };
}

export default useCards;
