import { RequestOptions } from '#mrktbox/clerk/types';

import { APIError, Method, request as baseRequest } from '#mrktbox/clerk/api';

import { getApiDomain } from '#mrktbox/clerk/utils';

export type { RequestOptions }  from '#mrktbox/clerk/types';

export function getUrl(
  path : string,
  params? : { [key : string] : string } | string[][],
) {
  if (params) {
    if (Array.isArray(params)) {
      params = params.reduce((acc, [key, value]) => {
        if (value) acc.push([key, value]);
        return acc;
      }, [] as [string, string][]);
    } else {
      params = Object.entries(params).reduce((acc, [key, value]) => {
        if (value) acc.push([key, value]);
        return acc;
      }, [] as [string, string][]);
    }
  }

  const domain = getApiDomain();
  if (!domain) { console.warn('API domain not configured'); }

  let url = `${domain}/${path}`;
  if (params) url += `?${new URLSearchParams(params)}`;
  return url;
}

export function formatDate(date : Date) {
  return date.toISOString().split('T')[0];
}

export function parseDate(date : string) {
  return ;
}

export async function request(
  url : string,
  method : Method,
  body? : any,
  options? : RequestOptions,
) {
  const response = await baseRequest(
    url,
    method,
    body,
    options?.token ?? '',
  );

  if (response.error) {
    throw new APIError(
      response.error.message,
      response.error.code,
      response.error.key || response.error.reason || '',
    );
  }

  return response;
}
