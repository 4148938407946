import { useCallback } from 'react';

import {
  Address,
  Customer,
  CreditCard,
  ServiceChannel,
  Location,
  TimeSlot,
  Product,
  Adjustment,
  AppliedAdjustment,
  LineItem,
  Fulfilment,
  Order,
  Hold,
} from '#mrktbox/clerk/types';

import {
  claimGuestCode as claimGuestCodeAPI,
  createLineItem as createLineItemAPI,
  retrieveLineItems as retrieveLineItemsAPI,
  retrieveLineItem as retrieveLineItemAPI,
  updateLineItem as updateLineItemAPI,
  deleteLineItem as deleteLineItemAPI,
  bulkCreateLineItems as bulkCreateLineItemsAPI,
  bulkUpdateLineItems as bulkUpdateLineItemsAPI,
  bulkDeleteLineItems as bulkDeleteLineItemsAPI,
  createFulfilment as createFulfilmentAPI,
  createLineItemFulfilment as createLineItemFulfilmentAPI,
  updateFulfilment as updateFulfilmentAPI,
  deleteFulfilment as deleteFulfilmentAPI,
  bulkCreateFulfilments as bulkCreateFulfilmentsAPI,
  bulkUpdateFulfilments as bulkUpdateFulfilmentsAPI,
  bulkDeleteFulfilments as bulkDeleteFulfilmentsAPI,
  addAdjustmentToFulfilment as addAdjustmentToFulfilmentAPI,
  removeAdjustmentFromFulfilment as removeAdjustmentFromFulfilmentAPI,
  retrieveOrders as retrieveOrdersAPI,
  retrieveOrder as retrieveOrderAPI,
  deleteOrder as deleteOrderAPI,
  bulkCreateOrders as bulkCreateOrdersAPI,
  bulkUpdateOrders as bulkUpdateOrdersAPI,
  addAdjustmentToOrder as addAdjustmentToOrderAPI,
  removeAdjustmentFromOrder as removeAdjustmentFromOrderAPI,
  createHold as createHoldAPI,
  retrieveHolds as retrieveHoldsAPI,
  retrieveHold as retrieveHoldAPI,
  deleteHold as deleteHoldAPI,
  payOrder as payOrderAPI,
  payOrders as payOrdersAPI,
  payOrdersWithFeedback as payOrdersWithFeedbackAPI,
} from '#mrktbox/clerk/api/mrktbox/orders';

import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useOrdersAPI() {
  const processRequest = useProcessRequest();

  const claimGuestCode = useCallback(
    async (
      customer : Customer,
      args? : { token? : string }
    ) => {
      return await processRequest(
        claimGuestCodeAPI,
        { customer, ...args },
      )
    },
    [processRequest],
  );

  const createLineItem = useCallback(
    async (
      lineItem : LineItem,
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
        product? : Product,
      },
    ) => {
      return await processRequest(
        createLineItemAPI,
        { lineItem, ...options },
      )
    },
    [processRequest],
  );

  const retrieveLineItems = useCallback(async (
    options? : { since? : Date }
  ) => {
    return await processRequest(
      retrieveLineItemsAPI,
      { since : options?.since },
    )
  }, [processRequest])

  const retrieveLineItem = useCallback(
    async (lineItemId : number) => {
      return await processRequest(
        retrieveLineItemAPI,
        { lineItemId },
      )
    },
    [processRequest],
  );

  const updateLineItem = useCallback(
    async (
      lineItem : LineItem,
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
    ) => {
      return await processRequest(
        updateLineItemAPI,
        { lineItem, ...options },
      )
    },
    [processRequest],
  );

  const deleteLineItem = useCallback(async (lineItem : LineItem) => {
    return await processRequest(
      deleteLineItemAPI,
      { lineItem },
    )
  }, [processRequest]);

  const bulkCreateLineItems = useCallback(
    async (
      lineItems : LineItem[],
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
    ) => {
      return await processRequest(
        bulkCreateLineItemsAPI,
        { lineItems, ...options },
      )
    },
    [processRequest],
  );

  const bulkUpdateLineItems = useCallback(
    async (
      lineItems : LineItem[],
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
    ) => {
      return await processRequest(
        bulkUpdateLineItemsAPI,
        { lineItems, ...options },
      )
    },
    [processRequest],
  );

  const bulkDeleteLineItems = useCallback(
    async (lineItems : LineItem[]) => {
      return await processRequest(
        bulkDeleteLineItemsAPI,
        { lineItems },
      )
    },
    [processRequest],
  );

  const createFulfilment = useCallback(
    async (fulfilment : Fulfilment) => {
      return await processRequest(
        createFulfilmentAPI,
        { fulfilment },
      )
    },
    [processRequest],
  );

  const createLineItemFulfilment = useCallback(
    async (
      lineItem : LineItem,
      overrides : { quantity : number },
      order : {
        address : Address | null,
        serviceChannel : ServiceChannel,
        location : Location | null,
        timeSlot : TimeSlot,
        timeSlotIteration : number,
        timeSlotDivision : number,
      },
    ) => {
      return await processRequest(
        createLineItemFulfilmentAPI,
        { lineItem, overrides, order },
      )
    },
    [processRequest],
  );

  const updateFulfilment = useCallback(
    async (fulfilment : Fulfilment) => {
      return await processRequest(
        updateFulfilmentAPI,
        { fulfilment },
      )
    },
    [processRequest],
  );

  const deleteFulfilment = useCallback(
    async (fulfilment : Fulfilment) => {
      return await processRequest(
        deleteFulfilmentAPI,
        { fulfilment },
      )
    },
    [processRequest],
  );

  const bulkCreateFulfilments = useCallback(
    async (lineItems : LineItem[]) => {
      return await processRequest(
        bulkCreateFulfilmentsAPI,
        { lineItems },
      )
    },
    [processRequest],
  );

  const bulkUpdateFulfilments = useCallback(
    async (fulfilments : Fulfilment[]) => {
      return await processRequest(
        bulkUpdateFulfilmentsAPI,
        { fulfilments },
      )
    },
    [processRequest],
  );

  const bulkDeleteFulfilments = useCallback(
    async (fulfilments : Fulfilment[]) => {
      return await processRequest(
        bulkDeleteFulfilmentsAPI,
        { fulfilments },
      )
    },
    [processRequest],
  );

  const addAdjustmentToFulfilment = useCallback(
    async (
      fulfilment : Fulfilment,
      adjustment : Adjustment,
    ) => {
      return await processRequest(
        addAdjustmentToFulfilmentAPI,
        { fulfilment, adjustment },
      )
    },
    [processRequest],
  );

  const removeAdjustmentFromFulfilment = useCallback(
    async (
      fulfilment : Fulfilment,
      appliedAdjustment : AppliedAdjustment,
    ) => {
      return await processRequest(
        removeAdjustmentFromFulfilmentAPI,
        { fulfilment, appliedAdjustment },
      )
    },
    [processRequest],
  );

  const retrieveOrders = useCallback(async (
    options? : { since? : Date }
  ) => {
    return await processRequest(
      retrieveOrdersAPI,
      { since : options?.since },
    )
  }, [processRequest])

  const retrieveOrder = useCallback(
    async (orderId : number) => {
      return await processRequest(
        retrieveOrderAPI,
        { orderId },
      )
    },
    [processRequest],
  );

  const deleteOrder = useCallback(
    async (order : Order) => {
      return await processRequest(
        deleteOrderAPI,
        { order },
      )
    },
    [processRequest],
  );

  const bulkCreateOrders = useCallback(
    async (orders : {
      address : Address | null,
      customer : Customer,
      serviceChannel : ServiceChannel,
      location : Location | null,
      timeSlot : TimeSlot,
      timeSlotIteration : number,
      timeSlotDivision : number,
    }[]) => {
      return await processRequest(
        bulkCreateOrdersAPI,
        { orders },
      )
    },
    [processRequest],
  );

  const bulkUpdateOrders = useCallback(
    async (orders : Order[], { status } : { status : string }) => {
      return await processRequest(
        bulkUpdateOrdersAPI,
        { orders, status },
      )
    },
    [processRequest],
  );

  const addAdjustmentToOrder = useCallback(
    async (
      order : Order,
      adjustment : Adjustment,
    ) => {
      return await processRequest(
        addAdjustmentToOrderAPI,
        { order, adjustment },
      )
    },
    [processRequest],
  );

  const removeAdjustmentFromOrder = useCallback(
    async (
      order : Order,
      appliedAdjustment : AppliedAdjustment,
    ) => {
      return await processRequest(
        removeAdjustmentFromOrderAPI,
        { order, appliedAdjustment },
      )
    },
    [processRequest],
  );

  const createHold = useCallback(
    async (hold : Hold) => {
      return await processRequest(
        createHoldAPI,
        { hold },
      )
    },
    [processRequest],
  );

  const retrieveHolds = useCallback(async () => {
    return await processRequest(
      retrieveHoldsAPI,
      {},
    )
  }, [processRequest])

  const retrieveHold = useCallback(
    async (holdId : number) => {
      return await processRequest(
        retrieveHoldAPI,
        { holdId },
      )
    },
    [processRequest],
  );

  const deleteHold = useCallback(
    async (hold : Hold) => {
      return await processRequest(
        deleteHoldAPI,
        { hold },
      )
    },
    [processRequest],
  );

  const payOrder = useCallback(
    async (
      order : Order,
      options? : {
        card? : CreditCard,
        tokens? : string[],
        outOfSystem? : boolean,
      },
    ) => {
      return await processRequest(
        payOrderAPI,
        {
          order,
          card : options?.card,
          tokens : options?.tokens,
          outOfSystem : options?.outOfSystem,
        },
      )
    },
    [processRequest],
  );

  const payOrders = useCallback(
    async (
      orders : Order[],
      options? : { card? : CreditCard, token? : string },
    ) => {
      return await processRequest(
        payOrdersAPI,
        { orders, card : options?.card, token : options?.token },
      )
    },
    [processRequest],
  );

  const payOrdersWithFeedback = useCallback(
    async (
      orders : Order[],
      options? : { card? : CreditCard, token? : string },
    ) => {
      return await processRequest(
        payOrdersWithFeedbackAPI,
        { orders, card : options?.card, token : options?.token },
      )
    },
    [processRequest],
  );

  return {
    claimGuestCode,
    createLineItem,
    retrieveLineItems,
    retrieveLineItem,
    updateLineItem,
    deleteLineItem,
    bulkCreateLineItems,
    bulkUpdateLineItems,
    bulkDeleteLineItems,
    createFulfilment,
    createLineItemFulfilment,
    updateFulfilment,
    deleteFulfilment,
    bulkCreateFulfilments,
    bulkUpdateFulfilments,
    bulkDeleteFulfilments,
    addAdjustmentToFulfilment,
    removeAdjustmentFromFulfilment,
    retrieveOrders,
    retrieveOrder,
    deleteOrder,
    bulkCreateOrders,
    bulkUpdateOrders,
    addAdjustmentToOrder,
    removeAdjustmentFromOrder,
    createHold,
    retrieveHolds,
    retrieveHold,
    deleteHold,
    payOrder,
    payOrders,
    payOrdersWithFeedback,
  };
}

export default useOrdersAPI;
