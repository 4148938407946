import React from 'react';

import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

import { AlertColour } from '#materials/types';
import Banner from '#materials/Banner';

interface SnackPackProps {
  open : boolean;
  handleClose? : () => void;
  handleClosed? : () => void;
  colour? : AlertColour;
  icon? : React.ReactElement;
  actions? : React.ReactElement | React.ReactElement[];
  children? : React.ReactNode;
}

function SnackPack({
  children : message,
  open,
  handleClose : close,
  handleClosed : handleClose,
  colour,
  icon,
  actions,
} : SnackPackProps) {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical : 'bottom', horizontal : 'right' }}
      TransitionProps={{ onExited : handleClose }}
    >
      <Box
        sx={{
          minHeight : 48,
          minWidth : 400,
          maxWidth : 800,
        }}
      >
        <Banner
          onClose={close}
          colour={colour}
          icon={icon}
          actions={actions}
        >
          { message }
        </Banner>
      </Box>
    </Snackbar>
  );
}

export default SnackPack;
