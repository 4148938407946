import React, { useCallback, useEffect, useMemo } from 'react';

import { useParams, useSearchParams } from '#hooks/useNavigation';
import useNavigation from '#hooks/useNavigation';
import useCustomers from '#hooks/useCustomers';
import useCards from '#hooks/useCards';
import useServices from '#hooks/useServices';
import useRoutes from '#hooks/useRoutes';
import useAdjustments from '#hooks/useAdjustments';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';

import OrderIndex from '#components/orders/OrderIndex';
import OrderInfo from '#components/orders/OrderInfo';

import { parseDateTime } from '#utils/date';

function Orders() {
  const { orderId : idString } = useParams();
  const [urlParams] = useSearchParams();
  const { adjustSearchParams } = useNavigation();
  const { load : loadCustomers } = useCustomers();
  const { load : loadCards } = useCards();
  const { load : loadServices } = useServices();
  const { load : loadRoutes } = useRoutes();
  const { load : loadAdjustments } = useAdjustments();
  const { load : loadOrders, loaded: ordersLoaded } = useOrders();
  const { load : loadOptions } = useOptions();
  const { load : loadSubscriptions } = useSubscriptions();

  useEffect(() => {
    loadCustomers();
    loadCards();
    loadServices();
    loadRoutes();
    loadAdjustments();
    loadOrders();
    loadOptions();
    loadSubscriptions();
  }, [
    loadCustomers,
    loadCards,
    loadServices,
    loadRoutes,
    loadAdjustments,
    loadOrders,
    loadOptions,
    loadSubscriptions,
  ]);

  const setCustomer = useCallback((customerId : number | null) => {
    adjustSearchParams({ customer : customerId });
  }, [adjustSearchParams]);
  const setServiceChannel = useCallback((serviceChannelId : number | null) => {
    adjustSearchParams({ serviceChannel : serviceChannelId });
  }, [adjustSearchParams]);
  const setLocation = useCallback((locationId : number | null) => {
    adjustSearchParams({ location : locationId });
  }, [adjustSearchParams]);
  const setRoute = useCallback((routeId : number | false | null) => {
    adjustSearchParams({ route : routeId });
  }, [adjustSearchParams]);
  const setTimeSlot = useCallback((timeSlotId : number | null) => {
    adjustSearchParams({ timeSlot : timeSlotId });
  }, [adjustSearchParams]);
  const setFromDate = useCallback((date : Date | null) => {
    adjustSearchParams({ from : date?.toISOString() });
  }, [adjustSearchParams]);
  const setToDate = useCallback((date : Date | null) => {
    adjustSearchParams({ to : date?.toISOString() });
  }, [adjustSearchParams]);
  const setShowIncomplete = useCallback((showIncomplete : boolean) => {
    adjustSearchParams({ incomplete : showIncomplete ? 'true' : undefined });
  }, [adjustSearchParams]);
  const setShowGuest = useCallback((showGuest : boolean) => {
    adjustSearchParams({ guest : showGuest ? 'true' : undefined });
  }, [adjustSearchParams]);

  const isSearch = idString === 'search';
  const orderId = parseInt(idString ?? '');

  const addressId = urlParams.has('address')
    ? parseInt(urlParams.get('address') ?? '')
    : null;
  const customerId = parseInt(urlParams.get('customer') ?? '');
  const guestCode = urlParams.get('guestCode');
  const serviceChannelId = parseInt(urlParams.get('serviceChannel') ?? '');
  const locationId = urlParams.has('location')
    ? parseInt(urlParams.get('location') ?? '')
    : NaN;
  const routeId = urlParams.get('route') === 'false'
    ? false
    : parseInt(urlParams.get('route') ?? '');
  const timeParam = urlParams.get('timeSlot');
  const [
    timeSlotParam,
    iterationParam,
    divisionParam,
  ] = timeParam ? timeParam.split('-') : [];
  const timeSlotId = parseInt(timeSlotParam ?? '');
  const iteration = parseInt(iterationParam ?? '');
  const division = parseInt(divisionParam ?? '');
  const fromDate = parseDateTime(urlParams.get('from') ?? '');
  const toDate = parseDateTime(urlParams.get('to') ?? '');
  const showIncomplete = urlParams.get('incomplete') === 'true';
  const showGuest = urlParams.get('guest') === 'true';

  const validSearch = isSearch && (
    customerId
      || guestCode
      || serviceChannelId
      || locationId
      || addressId
      || timeSlotId
  );
  const infoProps = useMemo(() => (orderId
    ? { orderId }
    : (validSearch
      ? {
        addressId,
        customerId,
        guestCode,
        serviceChannelId,
        locationId,
        timeSlotId,
        iteration : isNaN(iteration) ? undefined : iteration,
        division : isNaN(division) ? undefined : division,
      } : null)
  ), [
    orderId,
    addressId,
    customerId,
    guestCode,
    serviceChannelId,
    locationId,
    timeSlotId,
    iteration,
    division,
    validSearch,
  ]);
  const indexProps = useMemo(() => ({
    ...(!isNaN(customerId) && { customerId }),
    ...(!isNaN(serviceChannelId) && { serviceChannelId }),
    ...(!isNaN(locationId) && { locationId }),
    ...(routeId === false
      ? { routeId : false as false }
      : !isNaN(routeId) && { routeId }),
    ...(timeSlotId && { timeSlotId }),
    ...(!isNaN(fromDate.getTime()) && { fromDate }),
    ...(!isNaN(toDate.getTime()) && { toDate }),
    ...(showIncomplete && { showIncomplete }),
    ...(showGuest && { showGuest }),
    setCustomer,
    setServiceChannel,
    setLocation,
    setRoute,
    setTimeSlot,
    setFromDate,
    setToDate,
    setShowIncomplete,
    setShowGuest,
  }), [
    customerId,
    serviceChannelId,
    locationId,
    routeId,
    timeSlotId,
    fromDate,
    toDate,
    showIncomplete,
    showGuest,
    setCustomer,
    setServiceChannel,
    setLocation,
    setRoute,
    setTimeSlot,
    setFromDate,
    setToDate,
    setShowIncomplete,
    setShowGuest,
  ]);

  if (!ordersLoaded) return null;

  return (
    !!infoProps
      ? (<OrderInfo {...infoProps} />)
      : (<OrderIndex query={indexProps} />)
  );
}

export default Orders;
