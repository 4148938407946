import { ResponseError, isResponseError } from './api';
import { Address } from './addresses';
import { Customer } from './customers';
import { Currency, Payment, isCurrency, isPayment } from './payments';
import { Service, ServiceChannel, Location } from './services';
import { TimeSlot } from './scheduling';

export type OrderError = 'noService'
  | 'serviceMismatch'
  | 'orphanedFulfilment'
  | 'emptyOrder'
  | 'noPayment';

export interface PaymentError extends ResponseError {
  orderId? : number;
}

export type FulfilmentStatus = 'pending'
  | 'confirmed'
  | 'cancelled'
  | 'inProgress'
  | 'ready'
  | 'fulfilled'

export interface AppliedAdjustment {
  id? : number;
  adjustmentId : number | null;
  applied : boolean;
  name : string;
  count : number;
  factor : number | null;
  currency : Currency | null;
}

interface AppliedTax {
  taxId : number;
  adjustmentId : number;
  rate : number;
}

export interface LineItem {
  id? : number;
  refId? : number | string;
  addressId : number | null;
  customerId : number | null;
  serviceChannelId : number | null;
  locationId : number | null;
  timeSlotId : number | null;
  productId : number;
  quantity : number;
  timeSlotIteration : number;
  timeSlotDivision : number;
  price : Currency | null;
  guestCode : string;
}

export interface Fulfilment {
  id? : number;
  orderId : number | null;
  lineItemId : number | null;
  status : FulfilmentStatus;
  requestedProductId : number;
  requestedQty : number;
  fulfilledProductId : number | null;
  fulfilledQty : number | null;
  unitPrice : Currency;
  price : Currency;
  appliedAdjustments : { [id : number] : AppliedAdjustment };
  taxes : { [id : number] : number };
}

export interface Order {
  id? : number;
  addressId : number | null;
  customerId : number | null;
  serviceChannelId : number | null;
  locationId : number | null;
  timeSlotId : number | null;
  serviceId : number | null;
  timeSlotIteration : number;
  timeSlotDivision : number;
  fulfilments : { [id : number] : Fulfilment };
  appliedAdjustments : { [id : number] : AppliedAdjustment };
  appliedTaxes : { [id : number] : AppliedTax };
  payments : { [id : number] : Payment };
  integrations? : { [id : number] : OrderIntegration };
}

export interface Hold {
  id? : number;
  start : Date;
  end : Date;
  serviceIds : number[];
}

export interface OrderIntegration {
  id : string;
  integrationId : number;
  externalId : string;
  orderId : number;
  data : any;
}

export interface Subtotal {
  key : string;
  total : Currency;
  label? : string;
  adjustmentId? : number | null;
  appliedAdjustmentIds? : number[];
  taxId? : number;
}

export interface DraftOrder {
  address : Address | null;
  customer : Customer | null;
  serviceChannel : ServiceChannel | null;
  location : Location | null;
  timeSlot : TimeSlot | null;
  service : Service | null;
  timeSlotIteration : number;
  timeSlotDivision : number;
  guestCode : string | null;
  time : Date | null;
  order? : Order | null;
  lineItems : { [id : number] : LineItem };
  totals : Subtotal[]
  complete : boolean;
  paid? : boolean;
  status? : FulfilmentStatus;
  errors : OrderError[];
}

export function isPaymentError(error : any) : error is PaymentError {
  return (
    typeof error === 'object' &&
    (error.orderId === undefined ||
      typeof error.orderId === 'number') &&
    isResponseError(error)
  );
}

export function isAppliedAdjustment(
  adjustment : any
) : adjustment is AppliedAdjustment {
  return (
    typeof adjustment === 'object' &&
    (adjustment.id === undefined || typeof adjustment.id === 'number') &&
    (adjustment.adjustmentId === null ||
      typeof adjustment.adjustmentId === 'number') &&
    typeof adjustment.applied === 'boolean' &&
    typeof adjustment.name === 'string' &&
    typeof adjustment.count === 'number' &&
    (adjustment.factor === null || typeof adjustment.factor === 'number') &&
    (adjustment.currency === null || isCurrency(adjustment.currency))
  );
}

export function isAppliedTax(tax : any) : tax is AppliedTax {
  return (
    typeof tax === 'object' &&
    typeof tax.taxId === 'number' &&
    typeof tax.adjustmentId === 'number' &&
    typeof tax.rate === 'number'
  );
}

export function isFulfilmentStatus(status : any) : status is FulfilmentStatus {
  return (
    status === 'pending' ||
    status === 'confirmed' ||
    status === 'cancelled' ||
    status === 'inProgress' ||
    status === 'ready' ||
    status === 'fulfilled'
  );
}

export function isLineItem(lineItem : any) : lineItem is LineItem {
  return (
    typeof lineItem === 'object' &&
    (lineItem.id === undefined || typeof lineItem.id === 'number') &&
    (lineItem.addressId === null || typeof lineItem.addressId === 'number') &&
    (lineItem.customerId === null || typeof lineItem.customerId === 'number') &&
    (lineItem.serviceChannelId === null ||
      typeof lineItem.serviceChannelId === 'number') &&
    (lineItem.locationId === null || typeof lineItem.locationId === 'number') &&
    (lineItem.timeSlotId === null || typeof lineItem.timeSlotId === 'number') &&
    typeof lineItem.productId === 'number' &&
    typeof lineItem.quantity === 'number' &&
    typeof lineItem.timeSlotIteration === 'number' &&
    typeof lineItem.timeSlotDivision === 'number' &&
    (lineItem.price === null
      || isCurrency(lineItem.price)) &&
    typeof lineItem.guestCode === 'string'
  );
}

export function isFulfilment(fulfilment : any) : fulfilment is Fulfilment {
  return (
    typeof fulfilment === 'object' &&
    (fulfilment.id === undefined || typeof fulfilment.id === 'number') &&
    typeof fulfilment.orderId === 'number' &&
    (fulfilment.lineItemId === null ||
      typeof fulfilment.lineItemId === 'number') &&
    isFulfilmentStatus(fulfilment.status) &&
    typeof fulfilment.requestedProductId === 'number' &&
    typeof fulfilment.requestedQty === 'number' &&
    (fulfilment.fulfilledProductId === null ||
      typeof fulfilment.fulfilledProductId === 'number') &&
    (fulfilment.fulfilledQty === null ||
      typeof fulfilment.fulfilledQty === 'number') &&
    isCurrency(fulfilment.unitPrice) &&
    isCurrency(fulfilment.price) &&
    typeof fulfilment.taxes === 'object' &&
    Object.keys(fulfilment.appliedAdjustments).every((key) => (
      typeof key === 'string' &&
      !isNaN(parseInt(key)) &&
      isAppliedAdjustment(fulfilment.appliedAdjustments[parseInt(key)])
    )) &&
    Object.keys(fulfilment.taxes).every((key) => (
      typeof key === 'string' &&
      !isNaN(parseInt(key)) &&
      typeof fulfilment.taxes[parseInt(key)] === 'number'
    ))
  );
}

export function isOrderIntegration(
  integration : any
) : integration is OrderIntegration {
  return (
    typeof integration === 'object' &&
    typeof integration.id === 'number' &&
    typeof integration.orderId === 'number' &&
    typeof integration.integrationId === 'number' &&
    typeof integration.externalId === 'string' &&
    typeof integration.data === 'object'
  );
}

export function isOrder(order : any) : order is Order {
  return (
    typeof order === 'object' &&
    (order.id === undefined || typeof order.id === 'number') &&
    (order.addressId === null || typeof order.addressId === 'number') &&
    (order.customerId === null || typeof order.customerId === 'number') &&
    (order.serviceChannelId === null ||
      typeof order.serviceChannelId === 'number') &&
    (order.locationId === null || typeof order.locationId === 'number') &&
    (order.timeSlotId === null || typeof order.timeSlotId === 'number') &&
    (order.serviceId === null || typeof order.serviceId === 'number') &&
    typeof order.timeSlotIteration === 'number' &&
    typeof order.timeSlotDivision === 'number' &&
    typeof order.fulfilments === 'object' &&
    Object.keys(order.fulfilments).every((key) => (
      typeof key === 'string' &&
      !isNaN(parseInt(key)) &&
      isFulfilment(order.fulfilments[key])
    )) &&
    typeof order.appliedAdjustments === 'object' &&
    Object.keys(order.appliedAdjustments).every((key) => (
      typeof key === 'string' &&
      !isNaN(parseInt(key)) &&
      isAppliedAdjustment(order.appliedAdjustments[key])
    )) &&
    typeof order.appliedTaxes === 'object' &&
    Object.keys(order.appliedTaxes).every((key) => (
      typeof key === 'string' &&
      !isNaN(parseInt(key)) &&
      isAppliedTax(order.appliedTaxes[key])
    )) &&
    typeof order.payments === 'object' &&
    Object.keys(order.payments).every((key) => (
      typeof key === 'string' &&
      !isNaN(parseInt(key)) &&
      isPayment(order.payments[key])
    )) &&
    (order.integrations === undefined || (
      typeof order.integrations === 'object' &&
      Object.keys(order.integrations).every((key) => (
        typeof key === 'string' &&
        !isNaN(parseInt(key)) &&
        isOrderIntegration(order.integrations[key])
      ))
    ))
  );
}

export function isHold(hold : any) : hold is Hold {
  return (
    typeof hold === 'object' &&
    (hold.id === undefined || typeof hold.id === 'number') &&
    hold.start instanceof Date &&
    hold.end instanceof Date &&
    Array.isArray(hold.serviceIds) &&
    hold.serviceIds.every((id : any) => typeof id === 'number')
  );
}
