import { useCallback } from 'react';

import {
  Address,
  ServiceChannel,
  Location,
  TimeSlot,
  LineItem,
  Selection,
  Subscription,
  SubscriptionOption,
} from '#mrktbox/clerk/types';

import {
  createSubscription as createSubscriptionAPI,
  retrieveSubscriptions as retrieveSubscriptionsAPI,
  retrieveSubscription as retrieveSubscriptionAPI,
  bulkCreateSubscriptions as bulkCreateSubscriptionsAPI,
  bulkDeleteSubscriptions as bulkDeleteSubscriptionsAPI,
  retrieveSubscriptionOptions as retrieveSubscriptionOptionsAPI,
  addServiceChannelToSubscriptionOption
    as addServiceChannelToSubscriptionOptionAPI,
  removeServiceChannelFromSubscriptionOption
    as removeServiceChannelFromSubscriptionOptionAPI,
  bulkCreateFulfilments as bulkCreateFulfilmentsAPI,
} from '#mrktbox/clerk/api/mrktbox/subscriptions';

import useProcessRequest from './useProcessRequest';

function useSubscriptionsAPI() {
  const processRequest = useProcessRequest();

  const createSubscription = useCallback(async (
    subscription : Subscription,
    selections? : Selection[],
    lineItem? : LineItem,
  ) => {
    return await processRequest(
      createSubscriptionAPI,
      { subscription, selections, lineItem },
    )
  }, [processRequest]);

  const retrieveSubscriptions = useCallback(async (
    options? : { since? : Date },
  ) => {
    return await processRequest(
      retrieveSubscriptionsAPI,
      { since : options?.since },
    )
  }, [processRequest]);

  const retrieveSubscription = useCallback(async (subscriptionId : number) => {
    return await processRequest(
      retrieveSubscriptionAPI,
      { subscriptionId },
    )
  }, [processRequest]);

  const bulkCreateSubscriptions = useCallback(async ({
    subscriptions,
    selections,
    lineItems,
  } : {
    subscriptions : Subscription[],
    selections? : Selection[],
    lineItems? : LineItem[],
  }) => {
    return await processRequest(
      bulkCreateSubscriptionsAPI,
      { subscriptions, selections, lineItems },
    )
  }, [processRequest]);

  const bulkDeleteSubscriptions = useCallback(async (
    lineItems : LineItem[],
    target : {
      address : Address | null,
      serviceChannel : ServiceChannel | null,
      location : Location | null,
      timeSlot : TimeSlot | null,
      timeSlotIteration : number,
      timeSlotDivision : number,
    },
    targetOptions : { only : boolean },
  ) => {
    return await processRequest(
      bulkDeleteSubscriptionsAPI,
      { lineItems, target, targetOptions },
    )
  }, [processRequest]);

  const retrieveSubscriptionOptions = useCallback(async () => {
    return await processRequest(
      retrieveSubscriptionOptionsAPI,
      {},
    )
  }, [processRequest]);

  const addServiceChannelToSubscriptionOption = useCallback(async ({
    option,
    serviceChannel,
  } : {
    option : SubscriptionOption,
    serviceChannel : ServiceChannel,
  }) => {
    return await processRequest(
      addServiceChannelToSubscriptionOptionAPI,
      { option, serviceChannel },
    )
  }, [processRequest]);

  const removeServiceChannelFromSubscriptionOption = useCallback(async ({
    option,
    serviceChannel,
  } : {
    option : SubscriptionOption,
    serviceChannel : ServiceChannel,
  }) => {
    return await processRequest(
      removeServiceChannelFromSubscriptionOptionAPI,
      { option, serviceChannel },
    )
  }, [processRequest]);

  const bulkCreateFulfilments = useCallback(async ({
    lineItems,
    selections,
    subscriptions,
    targetIteration,
  } : {
    lineItems? : LineItem[],
    selections? : Selection[],
    subscriptions? : Subscription[],
    targetIteration? : number,
  }) => {
    return await processRequest(
      bulkCreateFulfilmentsAPI,
      { lineItems, selections, subscriptions, targetIteration },
    )
  }, [processRequest]);

  return {
    createSubscription,
    retrieveSubscriptions,
    retrieveSubscription,
    bulkCreateSubscriptions,
    bulkDeleteSubscriptions,
    retrieveSubscriptionOptions,
    addServiceChannelToSubscriptionOption,
    removeServiceChannelFromSubscriptionOption,
    bulkCreateFulfilments,
  };
}

export default useSubscriptionsAPI;
