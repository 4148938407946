import React, { useCallback, useEffect, useState } from 'react';

import {
  Service,
  LineItem,
  Selection,
  DraftOrder,
  ProjectedOrder,
} from '#types';

import useMediaQuery from '#hooks/useMediaQuery';
import useNavigation from '#hooks/useNavigation';
import useNotifications from '#hooks/useNotifications';
import { useFormContext } from '#hooks/useForm';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Split from '#materials/Splt';
import Banner from '#materials/Banner';

import OrderTotals from '#components/orders/OrderTotals';
import OrderForm, {
  TABLE_KEYS,
  SELECTION_TABLE_KEYS,
  defaultTableKeys,
  defaultSelectionTableKeys,
} from '#components/orders/OrderForm';

import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.editOrder;
const localeButtonKeys = locale.keys.buttons;
const localeNotificationKeys = locale.keys.notifications;

const tableKeys = [...defaultTableKeys].filter(
  (key) => key !== TABLE_KEYS.icon,
);
const tabletTableKeys = [...tableKeys].filter(
  (key) => key !== TABLE_KEYS.productSku
    && key !== TABLE_KEYS.unit
    && key !== TABLE_KEYS.price,
);
const selectionTabletTableKeys = [...defaultSelectionTableKeys].filter(
  (key) => key !== SELECTION_TABLE_KEYS.unit
    && key !== SELECTION_TABLE_KEYS.price,
);

interface EditOrderProps {
  order : ProjectedOrder;
  active : boolean;
}

const EditOrder = ({ order : initialOrder, active } : EditOrderProps) => {
  const { isDesktop } = useMediaQuery();
  const { navigate } = useNavigation();
  const { createNotification } = useNotifications();
  const {
    state : order,
    editing,
    valid,
    setEditing,
  } = useFormContext<ProjectedOrder>();
  const {
    refreshOrder,
    refreshLineItems,
    evaluateOptions,
    generateOrderUrl,
  } = useOrders();
  const {
    refreshSelections,
  } = useOptions();
  const {
    refreshSubscriptions,
    bulkCreateLineItems,
    bulkUpdateLineItems,
    bulkDeleteLineItems,
    updateOrder,
    deleteOrder,
    buildLineItemSubscription,
    findProjectedOrder,
    isOrderRecurring,
  } = useSubscriptions();

  const [services, setServices] = useState<Service[]>([]);
  const [exisingOrder, setExistingOrder] = useState<DraftOrder | null>(null);
  const [deleting, setDeleting] = useState(false);
  const [deletingSubscription, setDeletingSubscription] = useState(false);
  const [confirmSubscription, setConfirmSubscription] = useState(false);
  const [editingLineItem, setEditingLineItem] = useState(false);

  const match = useCallback(async () => {
    if (!order) return;
    if (
      order.address === initialOrder.address &&
      order.customer === initialOrder.customer &&
      order.serviceChannel === initialOrder.serviceChannel &&
      order.location === initialOrder.location &&
      order.timeSlot === initialOrder.timeSlot &&
      order.timeSlotIteration === initialOrder.timeSlotIteration &&
      order.timeSlotDivision === initialOrder.timeSlotDivision &&
      order.selections === initialOrder.selections
    ) {
      setExistingOrder(null);
      return;
    }

    const foundOrder = await findProjectedOrder({
      ...order,
      iteration : order.timeSlotIteration,
      division : order.timeSlotDivision,
    });
    if (foundOrder) {
      setExistingOrder(foundOrder);
      return;
    }
  }, [initialOrder, order, findProjectedOrder]);

  const handleRefresh = useCallback(async () => {
    await Promise.all([
      initialOrder.order?.id && refreshOrder(initialOrder.order?.id),
      refreshLineItems(),
      refreshSelections(),
      refreshSubscriptions(),
    ]);
  }, [
    initialOrder,
    refreshOrder,
    refreshLineItems,
    refreshSelections,
    refreshSubscriptions,
  ]);

  const handleMove = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const handleCancelMove = useCallback(() => {
    setEditing(false);
  }, [setEditing]);

  const handleSave = useCallback(async () => {
    if (!order || !valid) return;
    if (!order.serviceChannel || !order.timeSlot) return;

    if (isOrderRecurring(order)) {
      setConfirmSubscription(true);
      return;
    }

    const success = await updateOrder(
      initialOrder,
      {
        address : order.address,
        serviceChannel : order.serviceChannel,
        location : order.location,
        timeSlot : order.timeSlot,
        timeSlotIteration : order.timeSlotIteration,
        timeSlotDivision : order.timeSlotDivision,
      },
      'this',
    );

    if (success) {
      createNotification({
        key : 'update-order-success',
        message : localize(localeNotificationKeys.orders.update.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
      setEditing(false);
      const orderUrl = generateOrderUrl(
        order,
        { relative : true, searchPrefix : 'search' },
      );
      navigate(orderUrl);
    } else {
      createNotification({
        key : 'update-order-error',
        message : localize(localeNotificationKeys.orders.update.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [
    initialOrder,
    order,
    valid,
    generateOrderUrl,
    updateOrder,
    isOrderRecurring,
    setEditing,
    createNotification,
    navigate,
  ]);

  const handleCancelSubscription = useCallback(() => {
    setConfirmSubscription(false);
  }, []);

  const handleConfirmSubscription = useCallback(
    (thisOnly : boolean) => async () => {
      if (!order || !valid || !initialOrder.timeSlot) return;
      if (!order.serviceChannel || !order.timeSlot) return;

      const success = await updateOrder(
        initialOrder,
        {
          address : order.address,
          serviceChannel : order.serviceChannel,
          location : order.location,
          timeSlot : order.timeSlot,
          timeSlotIteration : order.timeSlotIteration,
          timeSlotDivision : order.timeSlotDivision,
        },
        thisOnly ? 'this' : 'future',
      );

      if (success) {
        createNotification({
          key : 'update-order-success',
          message : localize(localeNotificationKeys.orders.update.success),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.primary,
        });
        setConfirmSubscription(false);
        const orderUrl = generateOrderUrl(
          order,
          { relative : true, searchPrefix : 'search' },
        );
        navigate(orderUrl);
      } else {
        createNotification({
          key : 'update-order-error',
          message : localize(localeNotificationKeys.orders.update.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
        });
      }
    },
    [
      initialOrder,
      order,
      valid,
      updateOrder,
      createNotification,
      generateOrderUrl,
      navigate,
    ],
  );

  const handleInitDelete = useCallback(async () => {
    if (!order) return;
    if (isOrderRecurring(order)) setDeletingSubscription(true);
    else setDeleting(true);
  }, [order, isOrderRecurring]);

  const handleCancelDelete = useCallback(async () => {
    setDeleting(false);
    setDeletingSubscription(false);
  }, []);

  const handleConfirmDelete = useCallback(
    (thisOnly : boolean) => async () => {
      if (!order) return;

      const recurring = isOrderRecurring(order);
      if (recurring && !initialOrder.timeSlot) return;

      const response = await deleteOrder(
        order,
        recurring && thisOnly ? 'this' : 'future',
      )
      if (response) {
        createNotification({
          key : 'delete-order-success',
          message : localize(localeNotificationKeys.orders.delete.success),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.primary,
        });
        navigate('/orders');
      } else {
        createNotification({
          key : 'delete-line-item-error',
          message : localize(localeNotificationKeys.lineItems.delete.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
        });
      }
    },
    [
      initialOrder,
      order,
      deleteOrder,
      isOrderRecurring,
      createNotification,
      navigate,
    ],
  );

  const handleAddLineItem = useCallback(async (
    lineItem : LineItem,
    selections? : Selection[],
    options? : { period? : number },
  ) => {
    const proposedSelections = (selections ?? []).map(
      (selection) => ({
        ...selection,
        id : undefined,
      }),
    );

    const update = {
      ...lineItem,
      id : undefined,
      serviceChannelId : initialOrder.serviceChannel?.id ?? null,
      locationId : initialOrder.location?.id ?? null,
      addressId : initialOrder.address?.id ?? null,
      timeSlotId : initialOrder.timeSlot?.id ?? null,
      timeSlotIteration : initialOrder.timeSlotIteration,
      timeSlotDivision : initialOrder.timeSlotDivision,
      price : lineItem.price ? { ...lineItem.price, id : undefined } : null,
    }

    const result = await bulkCreateLineItems([{
      lineItem : update,
      selections : proposedSelections,
      subscription : options?.period
        ? buildLineItemSubscription(
          update,
          proposedSelections,
          options,
        )
        : null,
    }])
    const lineItems = result?.lineItems ?? null;
    const newSelections = result?.selections ?? null;
    const subscriptions = result?.subscriptions ?? null;

    const subscriptionError = !subscriptions
      || (options?.period && !Object.values(subscriptions).length);

    if (!!order && !!lineItems && !!newSelections && !subscriptionError) {
      createNotification({
        key : 'create-line-item-success',
        message : localize(localeNotificationKeys.lineItems.create.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      if (!lineItems) {
        createNotification({
          key : 'create-line-item-error',
          message : localize(localeNotificationKeys.lineItems.create.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert
        });
      } else if (!newSelections) {
        createNotification({
          key : 'create-item-customisation-error',
          message : localize(localeNotificationKeys.orders.customise.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert
        });
      } else if (subscriptionError) {
        createNotification({
          key : 'create-subscription-error',
          message : localize(localeNotificationKeys.orders.subscribe.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert
        });
      }
    }
  }, [
    initialOrder,
    order,
    buildLineItemSubscription,
    bulkCreateLineItems,
    createNotification,
  ]);

  const handleUpdateLineItem = useCallback(async (
    lineItem : LineItem,
    selections? : Selection[],
    options? : {
      startIteration? : number,
      endIteration? : number | null,
      targetIteration? : number,
      period? : number
    },
  ) => {
    const response = await bulkUpdateLineItems([{
      lineItem,
      selections : selections ?? [],
      subscription : options?.period
        ? buildLineItemSubscription(
          lineItem,
          selections ?? [],
          options,
        )
        : null,
    }]);

    if (response) {
      createNotification({
        key : 'update-line-item-success',
        message : localize(localeNotificationKeys.lineItems.update.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'update-line-item-error',
        message : localize(localeNotificationKeys.lineItems.update.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert
      });
    }
  }, [
    buildLineItemSubscription,
    bulkUpdateLineItems,
    createNotification,
  ]);

  const handleDeleteLineItem = useCallback(async (
    lineItem : LineItem,
    options? : { startIteration? : number, endIteration? : number | null },
  ) => {
    if (!order) return;
    if (
      options?.endIteration
        && (options.startIteration !== options.endIteration)
    ) return;

    const subscription = await bulkDeleteLineItems(
      [lineItem],
      order,
      options ? (options.endIteration ? 'this' : 'future') : 'this',
    );

    if (subscription) {
      createNotification({
        key : 'delete-line-item-success',
        message : localize(localeNotificationKeys.lineItems.delete.success),
        icon : (<Icon icon={settings.svgIcons.refresh} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-line-item-error',
        message : localize(localeNotificationKeys.lineItems.delete.error),
        icon : (<Icon icon={settings.svgIcons.refresh} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [order, bulkDeleteLineItems, createNotification]);

  useEffect(() => {
    if (active) return;
    setEditing(false);
  }, [active, setEditing]);

  useEffect(() => {
    if (!order) {
      setServices([]);
      return;
    }
    const { services } = evaluateOptions(order);
    setServices(services);
  }, [order, evaluateOptions]);

  useEffect(() => { if (!editing) setConfirmSubscription(false); }, [editing]);
  useEffect(() => { match(); }, [match]);

  const inProgress = [
    'inProgress',
    'ready',
    'fulfilled',
    'cancelled',
  ].includes(initialOrder.status ?? 'pending');

  return (
    <>
      { confirmSubscription && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          actions={(
            <>
              <Button onClick={handleConfirmSubscription(true)}>
                { localize(localeContentKeys.updateThis) }
              </Button>
              <Button onClick={handleConfirmSubscription(false)}>
                { localize(localeContentKeys.updateFuture) }
              </Button>
              <Button onClick={handleCancelSubscription}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.secondary}
        >
          { localize(localeContentKeys.confirmUpdate) }
        </Banner>
      ) }
      { deleting && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button onClick={handleConfirmDelete(true)}>
                { localize(localeButtonKeys.delete) }
              </Button>
              <Button onClick={handleCancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { deletingSubscription && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.delete} />)}
          actions={(
            <>
              <Button onClick={handleConfirmDelete(true)}>
                { localize(localeContentKeys.deleteThis) }
              </Button>
              <Button onClick={handleConfirmDelete(false)}>
                { localize(localeContentKeys.deleteFuture) }
              </Button>
              <Button onClick={handleCancelDelete}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.confirmDelete) }
        </Banner>
      ) }
      { (editing && !deleting && !confirmSubscription ) && (
        <Banner
          icon={valid
            ? (exisingOrder
              ? (<Icon icon={settings.svgIcons.info} />)
              : (<Icon icon={settings.svgIcons.check} />))
            : (<Icon icon={settings.svgIcons.clear} />)
          }
          actions={exisingOrder
            ? (
              <Button
                href={
                  generateOrderUrl(
                    exisingOrder,
                    { relative : true, searchPrefix : 'search' },
                  )
                }
              >
                { exisingOrder.paid
                  ? localize(localeButtonKeys.view)
                  : localize(localeButtonKeys.edit)
                }
              </Button>
            )
            : undefined
          }
          colour={valid
            ? (exisingOrder
              ? exisingOrder.paid
                ? settings.colours.alert.alert
                : settings.colours.alert.secondary
            : settings.colours.alert.primary)
            : settings.colours.alert.alert
          }
        >
          { valid
            ? (exisingOrder
              ? exisingOrder.paid
                ? localize(localeContentKeys.paidOrder)
                : localize(localeContentKeys.existingOrder)
              : localize(localeContentKeys.fulfillingServices) +
                ` ${services.map((service) => service.name).join(', ')}.`)
            : localize(localeContentKeys.invalidOptions)
          }
        </Banner>
      ) }
      <Split
          left={(
            <>
              { editing
                ? (
                  <>
                    <Button
                      onClick={handleSave}
                      disabled={
                        !valid
                          || exisingOrder?.paid
                          || deleting
                          || deletingSubscription
                          || confirmSubscription
                        }
                    >
                      { localize(localeButtonKeys.save) }
                    </Button>
                    <Button
                      onClick={handleCancelMove}
                      colour={settings.colours.button.alert}
                      disabled={
                        deleting
                          || deletingSubscription
                          || confirmSubscription
                      }
                    >
                      { localize(localeButtonKeys.cancel) }
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={handleRefresh}
                      disabled={editingLineItem}
                    >
                      { localize(localeButtonKeys.refresh) }
                    </Button>
                    { (!order?.paid && !inProgress) && (
                      <>
                        <Button
                          onClick={handleMove}
                          disabled={editingLineItem}
                        >
                          { localize(localeButtonKeys.move ) }
                        </Button>
                        <Button
                          onClick={handleInitDelete}
                          disabled={editingLineItem}
                          colour={settings.colours.button.alert}
                        >
                          { localize(localeButtonKeys.delete) }
                        </Button>
                      </>
                    ) }
                  </>
                )
              }
            </>
          )}
      />
      <OrderForm
        order={initialOrder}
        active={active}
        mode='edit'
        showNotes
        disableActions={editing}
        disableCustomer={true}
        disableProduct={true}
        disabled={
          order?.paid
            || inProgress
            || deleting
            || deletingSubscription
            || confirmSubscription
        }
        onAddLineItem={handleAddLineItem}
        onUpdateLineItem={handleUpdateLineItem}
        onRemoveLineItem={handleDeleteLineItem}
        setEditingItem={setEditingLineItem}
        tableKeys={isDesktop ? tableKeys : tabletTableKeys}
        selectionTableKeys={
          isDesktop
            ? defaultSelectionTableKeys
            : selectionTabletTableKeys
        }
      />
      <OrderTotals
        order={initialOrder}
        mode="edit"
        disabled={initialOrder.paid || inProgress}
      />
    </>
  );
}

export default EditOrder;
