import {
  Customer,
  CreditCard,
  isCreditCard,
} from '#mrktbox/clerk/types'

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const CREDIT_CARDS_PATH = 'accounts/credit-cards/';

function parseCreditCard(creditCard : any) {
  if (!isCreditCard(creditCard))
    throw new TypeError('credit card is not a CreditCard Object')
  return creditCard;
}

function parseCreditCards(creditCards : any) {
  const parsedCreditCards : { [id : number] : CreditCard } = {};
  for (const creditCardId in creditCards) {
    if (typeof creditCardId !== 'string')
      throw new TypeError('Credit Card id is not a string');

    const id = parseInt(creditCardId);
    if (isNaN(id)) throw new TypeError('Credit Card id is not a number');

    parsedCreditCards[id] = parseCreditCard(creditCards[id]);
  }

  return parsedCreditCards;
}

export async function retrieveCreditCards(
  input? : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${CREDIT_CARDS_PATH}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseCreditCards(response.creditCards);
  } catch {
    throw new DeserializationError(
      'Could not parse credit card list',
      response,
    );
  }
}

export async function retrieveCreditCard(
  { cardId } : { cardId : number},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${CREDIT_CARDS_PATH}${cardId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseCreditCard(response.creditCard);
  }
  catch {
    throw new DeserializationError(
      'Could not deserialize credit card',
      response,
    );
  }
}

export async function createCreditCard({
  creditCard,
  customer,
  postal,
  token,
} : {
  creditCard : CreditCard,
  customer : Customer,
  postal? : string,
  token : string,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${CREDIT_CARDS_PATH}`),
    methods.post,
    {
      creditCard : { ...creditCard, customerId : customer.id },
      ...((postal !== undefined) ? { postal } : {}),
      token,
    },
    options,
  );

  try {
    return parseCreditCard(response.creditCard);
  }
  catch {
    throw new DeserializationError(
      'Could not deserialize credit card',
      response,
    );
  }
}

export async function deleteCreditCard({
  creditCard
} : { creditCard : CreditCard }, options? : RequestOptions) {
  const response = await request(
    getUrl(`${CREDIT_CARDS_PATH}${creditCard.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseCreditCard(response.creditCard);
  } catch {
    throw new DeserializationError(
      'Could not deserialize credit card',
      response,
    );
  }
}

export async function pullCreditCards({
  customer,
} : {
  customer : Customer,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${CREDIT_CARDS_PATH}pull/`),
    methods.post,
    { customerId : customer.id },
    options,
  );

  try {
    return parseCreditCards(response.creditCards);
  } catch {
    throw new DeserializationError(
      'Could not deserialize credit card list',
      response,
    );
  }
}
